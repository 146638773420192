@import "redcircle-ui/scss/colors";

$primary-color--hover: hsl(360, 90%, 69%);

.button {
  font-weight: 500;
}

.secondary {
  color: $primary-color !important;
  border: 2px solid $primary-color !important;
  &:hover {
    color: $primary-color--hover !important;
    border: 2px solid $primary-color--hover !important;
  }
}

.icon {
  padding: 0 !important;
  svg,
  span {
    width: inherit;
    height: inherit;
  }
}

.mini {
  height: 28px !important;
  font-size: 13px !important;
}

.flex {
  white-space: normal;
  height: auto !important;
}
