@import "redcircle-ui/scss/colors";
@import "src/styles/utility";

.warning-panel {
  margin-bottom: 40px;
  background: $red-lighter;
  background-image: none !important;
  &.alert-warning {
    background: #fcf8e3;
  }
  .close {
    position: relative;
    width: 10px;
    min-width: auto;
  }
}

.warning-panel-exit {
  display: block;
  height: auto;
}
.warning-panel-exit-active {
  height: 0px;
  transition: all 500ms ease-in;
}
.warning-panel-exit-done {
  display: none;
}

// WARNING PANEL 2

.warning-panel-v2 {
  border-radius: 8px;
  padding: 16px 32px;

  &.outline {
    border: 1px solid $primary-color;
    border-left-width: 8px;
  }

  &.disabled {
    * {
      color: rgba(black, 0.3);
    }
    border-color: rgba(black, 0.3);
  }
  &.shadow {
    box-shadow: 0px 4px 36px rgba(159, 182, 200, 0.2);
  }

  .title {
    @include set-font($font-family: "Gilroy-Bold", $font-size: 20px, $line-height: 28px);
  }

  .subtitle {
    @include set-font(
      $font-family: "Gilroy-Bold",
      $font-size: 11px,
      $line-height: 16px,
      $letter-spacing: 1px
    );
  }

  .text {
    @include set-font($font-family: "Gilroy-Regular", $font-size: 15px, $line-height: 24px);
  }
}
