@import "../../scss/colors";

.button {
  background: none;
  color: inherit;
  border: 1px solid transparent;
  outline: none;
  user-select: text;
  border-radius: 8px;
  transition: border 0.2s ease-in-out, padding 0.2s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid $gray-dark;
    padding: 0.5rem;
    cursor: pointer;
    &::after {
      content: "⌄";
      font-size: 80%;
      color: $primary-color;
    }
  }
}

.expanded {
  &:hover,
  &:focus {
    &::after {
      content: "⌃";
      font-size: 80%;
      color: $primary-color;
    }
  }
}
