@import "redcircle-ui/scss/utility";

.transaction-table {
  .album-art {
    height: 50px;
    width: 50px;
    border-radius: 3px;
    @include box-shadow(2);
  }

  .transaction-info {
    min-width: 0;
  }

  .transaction-receipt {
    margin: 2px 20px 0 10px;
    flex: 0;
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.3em;
    }
  }

  .transaction-display {
    line-height: 1.2em;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 10px;
    min-width: 0;
  }

  .transaction-row {
    height: 100%;
    justify-content: space-between;
  }

  .transaction-title {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
  }

  .transaction-amount-column {
    font-weight: bold;
    text-align: right;
    font-size: 18px;
    line-height: 24px;
  }

  .rt-td:last-child {
    max-width: fit-content;
    margin: auto;
  }

  .rt-td {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pending-opacity {
    opacity: 0.2;
  }

  .exclusive-tag.tag-under-investigation {
    color: #996236 !important;
    background: #ffdbbe !important;
  }

  .investigation-notes {
    white-space: normal;
    margin: 10px 20px 0px 0px;
  }
}
