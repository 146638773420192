.payment-methods {
  .payment-methods-row {
    height: 80px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(black, 0.1);
    .card-name {
      margin: 0;
      min-width: 100px;
    }
    .last-4,
    .exp {
      margin-right: 30px;
      font-size: 11px;
      letter-spacing: 1px;
    }
  }
  &__spinner-container {
    position: absolute;
    top: 40vh;
  }
}
