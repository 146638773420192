@import "../../scss/colors";
@import "../../scss/spacing";

.banner {
  width: 100%;
  background: $primary-color;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  @extend .p-axs
}
