.react-phone-number-input__icon {
  border: none;
}

.user-form__verify-button {
  margin-left: 10px;
  margin-bottom: 0px !important;
  position: relative;
  top: -1px;
}
