@import "src/styles/utility";

.mapbox__map-toggles {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  .btn-group {
    border: none;
  }
  .btn.btn-default {
    margin: 0;
  }
}

#analytics-mapbox-container {
  position: relative;
  width: 100%;
  height: 100%;

  $bgColor: #f5f5f5;
  $bgHoverColor: rgba(0, 0, 0, 0.1);
  $color: #979797;

  & .mapboxgl-ctrl-icon {
    background: $bgColor;

    &:hover {
      background: $bgHoverColor;
    }

    &.mapboxgl-ctrl-zoom-in,
    &.mapboxgl-ctrl-zoom-out {
      @include set-font("Regular", $font-size: 24px, $line-height: 1em, $letter-spacing: 0.6px);
      color: $color;
    }

    &.mapboxgl-ctrl-zoom-in {
      &::after {
        content: "+";
      }
    }

    &.mapboxgl-ctrl-zoom-out {
      &::after {
        content: "-";
      }
    }
  }

  & .mapboxgl-control-container {
    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
      display: none;
    }
  }

  & .hidden {
    display: none;
  }
}
