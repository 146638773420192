@import "./_layout";
@import "./_spacing";
@import "./_colors";
@import "./_typography";
@import "./_media";
@import "./_utility";

// overrides for all RedCircle projects

a {
  text-decoration: none;
}

// ANTD OVERRIDES
// show arrows for Carousel component
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}
