@import "redcircle-ui/scss/utility";

.close * {
  fill: #666;
}

.drawer {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  & > div {
    margin-top: 10px;
  }
  & > button {
    padding: 0;
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 15px;
  overflow: auto;
}

.footer {
  flex: 0;
  padding: 15px;
  @include box-shadow(5);
}
