@import "redcircle-ui/scss/spacing";

// Helper classes for setting line height and font-size
// will move this to redcircle brand repo when ready to organize

@each $name, $size in $sizes {
  .lh-#{if($name != "",$name, "")} {
    line-height: $size !important;
  }

  .fs-#{if($name != "",$name, "")} {
    font-size: $size !important;
  }
}

// Sizes that are not part of the current $sizes map, but used in the app
$extraSizes: 9, 10, 11, 12, 13, 14, 15, 18, 20, 22, 28, 36;

@each $extraSize in $extraSizes {
  .fs-#{$extraSize} {
    font-size: ($extraSize * 1px) !important;
  }

  .lh-#{$extraSize} {
    line-height: ($extraSize * 1px) !important;
  }
}

$letterSpaceSizes: 1;

@each $letterSpaceSize in $letterSpaceSizes {
  .ls-#{$letterSpaceSize} {
    letter-spacing: ($letterSpaceSize * 1px) !important;
  }
}

//master config of allowed fonts and their font weights
$ConfigFontWeightMap: (
  Gilroy: (
    300: "Light",
    400: "Regular",
    600: "SemiBold",
    700: "Bold",
  ),
);
// default font
$default: Gilroy;

//backupfonts
$backups: '"Helvetica Neue", Helvetica, Arial, sans-serif';

// Helper to set font styles
@mixin set-font(
  $font-family: null,
  $font-weight: null,
  $font-size: null,
  $line-height: null,
  $font-style: null,
  $letter-spacing: null,
  $mediaQuery: null
) {
  // Checks if family name parameter is using the font weight strings "Light", "Regular", "SemiBold", etc,
  // for the current default font;
  @if (isUsingWeightStrings($font-family)) {
    $font-family:
      #{$default}-#{convertOrCleanWeightToString($font-family)},
      #{$backups};
    $font-weight: null;
  } @else if (containsString($font-family, Gilroy)) {
    $font-family:
      smartConvertToFontFamily($font-family, $font-weight, Gilroy),
      #{$backups};
    $font-weight: null;
  }
  //when adding other fonts just append if statement with font logic
  // @else if (containsString(($font-family),"newFont" )) {
  //  $font-family: myNewConvertLogic()
  // }

  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  font-style: $font-style;
  letter-spacing: $letter-spacing;
}

// EXAMPLE USE CASES

//@include set-font() => does nothing;

// @include set-font(
// $font-size: 20px,
// $line-heigt: 10px,
// $letter-spacing: .6px
//) => font-size: 20px, line-height: 10xp, letter-spacing: .6px;

// @include set-font(Light)  => font-family: Gilroy-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
// @include set-font(Regular)  => font-family: Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
// @include set-font(SemiBold)  => font-family: Gilroy-SemiBold, "Helvetica Neue", Helvetica, Arial, sans-serif;
// @include set-font(Bold)  => font-family: Gilroy-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;

// @include set-font(Gilroy, Light)  => font-family: Gilroy-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
// @include set-font(Gilroy, Regular)  => font-family: Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
// @include set-font(Gilroy, Bold)  => font-family: Gilroy-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;

// @include set-font(Gilroy, 300)  => font-family: Gilroy-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
// @include set-font(Gilroy, 400)  => font-family: Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
// @include set-font(Gilroy, 700)  => font-family: Gilroy-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;

/* @include set-font(Gilroy, 700, 14px, 18px, normal, .6px)  =>   font-family: Gilroy-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif; 
                                                                  font-size: 14px; 
                                                                  line-height: 18px;
                                                                  font-style: normal; 
                                                                  letter-spacing: .6px;*/

// Generic helper functions that help with readability
@function isString($val) {
  @return type-of($val) == string;
}

@function isNumber($val) {
  @return type-of($val) == number;
}

@function isMap($val) {
  @return type-of($val) == map;
}

@function containsString($string, $subString) {
  @return isString($string) and isString($subString) and
    isNumber(str-index(to-upper-case($string), to-upper-case($subString)));
}

@function stringEndsWith($string, $subString) {
  @if (
    str-length($string) >=
      str-length($subString) and
      isNumber(str-index(to-upper-case($string), to-upper-case($subString)))
  ) {
    @return to-upper-case(
        str-slice($string, str-index(to-upper-case($string), to-upper-case($subString)))
      )
      == to-upper-case($subString);
  }

  @return false;
}

@function map-has-value($map, $valueToCheck) {
  @if (isMap($map)) {
    @each $key, $prop in $map {
      @if ($prop == $valueToCheck) {
        @return true;
      }
    }
    @return false;
  } @else {
    @error "Did not provide a map into function map-has-value";
  }
}

// Set-Font specific helper functions

// checks if the basefont is found in Config Font Weight Map, and checks if the weight string is allowed for that base font.
@function containsAllowedWeightString($weightString, $baseFont) {
  @if (map-has-key($ConfigFontWeightMap, $baseFont)) {
    $baseFontWeightMap: map-get($ConfigFontWeightMap, $baseFont);
    @return map-has-value($baseFontWeightMap, $weightString);
  } @else {
    @return false;
  }
}

// Converts weight number to string version, if provided a weight string will check the string is a corerct weight string and return it
@function convertOrCleanWeightToString($val) {
  $conversionMap: (
    100: "Thin",
    200: "ExtraLight",
    300: "Light",
    400: "Regular",
    500: "Medium",
    600: "SemiBold",
    700: "Bold",
    800: "ExtraBold",
    900: "Black",
    "THIN": "Thin",
    "EXTRALIGHT": "ExtraLight",
    "LIGHT": "Light",
    "REGULAR": "Regular",
    "MEDIUM": "Medium",
    "SEMIBOLD": "SemiBold",
    "BOLD": "Bold",
    "EXTRABOLD": "ExtraBold",
    "BLACK": "Black",
  );

  @if (isNumber($val) and map-has-key($conversionMap, $val)) {
    @return map-get($conversionMap, $val);
  }
  // if provided weight string, will correctly format it to capitalize
  @else if (isString($val) and map-has-key($conversionMap, to-upper-case($val))) {
    @return map-get($conversionMap, to-upper-case($val));
  } @else {
    @return null;
  }
}

// Logic to get correct font-family string based on font family and weight provided for the current font;
// basically checks if the font family and its corresponding weight is allowed, converts the weight to string if necessary
@function smartConvertToFontFamily($family, $weight, $baseFont, $spacer: "-") {
  @if (
    familyContainsWeight($family) and
      containsAllowedWeightString(extractWeightFromFamily($family), $baseFont)
  ) {
    @return #{$baseFont}#{$spacer}#{extractWeightFromFamily($family)};
  } @else if (containsAllowedWeightString(convertOrCleanWeightToString($weight), $baseFont)) {
    @return #{$baseFont}#{$spacer}#{convertOrCleanWeightToString($weight)};
  } @else {
    @return #{$baseFont}-Regular;
  }
}

// Simple Helper function to check for default font weight strings;
@function isUsingWeightStrings($family) {
  @return containsAllowedWeightString($family, $default);
}

@function familyContainsWeight($font-family) {
  $weights: (
    "Thin",
    "ExtraLight",
    "Light",
    "Regular",
    "Medium",
    "SemiBold",
    "Bold",
    "ExtraBold",
    "Black"
  );

  @each $weight in $weights {
    @if (stringEndsWith($font-family, $weight)) {
      @return true;
    }
  }

  @return false;
}

@function extractWeightFromFamily($font-family) {
  $weights: (
    "Thin",
    "ExtraLight",
    "Light",
    "Regular",
    "Medium",
    "SemiBold",
    "Bold",
    "ExtraBold",
    "Black"
  );

  @each $weight in $weights {
    @if (stringEndsWith($font-family, $weight)) {
      @return $weight;
    }
  }
}
