.advertiser-sign-up-page {
  label.question-label {
    margin-bottom: 24px;
    font-size: 15px !important;
    line-height: 24px !important;
    font-weight: normal;
  }
  .react-phone-number-input__input {
    border: 1px solid #cccccc;
  }
}
