.overview {
  height: 120px;
  margin-bottom: 24px;
  background: #fafafa;
  background: #fafafa;
  border-radius: 4px;

  width: 100%;
  min-width: 100%;
  // overflow: hidden;
}

.zoomview {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  background-color: #e8f4fa;

  width: 100%;
  min-width: 100%;

  &_controls {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    height: 40px;
    padding: 0px 10px;

    &__icon {
      height: 22px;
      color: "#EA404D";
      margin: 0px 2px;
      cursor: pointer;
    }

    &__time {
      $timeBoxWidth: 90px;

      width: $timeBoxWidth;
      min-width: $timeBoxWidth;
      max-width: $timeBoxWidth;
      border: 1px solid #999999;
      border-radius: 2px;
      padding: 0px;
      margin: 0px 10px;
      text-align: center;
      letter-spacing: 0.15em;
    }

    &__duration {
      padding: 0.15em 0px 0px 5px;
      border-left: 1px solid black;
      letter-spacing: 1.5px;
      font-variant-numeric: tabular-nums;
    }
  }

  &_waveform {
    border-top: 1px solid #577d9e;
    border-radius: 4px;
    height: 100px;
    width: 100%;
    overflow: hidden;
  }

  margin-bottom: 10px;
}
