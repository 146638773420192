@import "redcircle-ui/scss/utility";

.breadcrumbs {
  margin-bottom: 32px;

  ol {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    flex-wrap: wrap;
  }

  a {
    color: inherit;
  }

  li {
    max-width: 25rem;
    &:first-child {
      white-space: nowrap;
    }
    &:not(:first-child) {
      @include line-clamp(1);
    }
  }
}

.inactive {
  font-weight: bold;
}

.icon {
  min-width: 12px;
}
