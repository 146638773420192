@import "redcircle-ui/scss/colors";

.promotions-intro-modal-row,
.product-intro-modal-row {
  display: flex;
  padding: 20px 0px;
  .promotions-intro-modal-row__number,
  .product-intro-modal-row__number {
    background: $primary-color;
    height: 40px;
    min-width: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    color: white;
    font-size: 20px;
    margin-right: 20px;
    font-weight: bold;
    span {
      position: relative;
      top: 1px;
    }
  }
}

.cross-promotion-opt-in-row__title {
  margin-top: 40px;
  margin-bottom: 10px;
}

.promo-intro-step__form_step_dots {
  margin-top: 108px;
}

.promo-intro-step__continue {
  margin-top: 40px;
  width: 100%;
}

.promo-intro-step__description {
  line-height: 24px;
}

.promo-intro-step__title {
  margin-top: 36px;
}
