.timeBlock {
  display: inline-block;

  padding: 0px 12px;
  margin: 0px 10px;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.15em;

  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 4px;
}

.show_title {
  color: #979797;
}
