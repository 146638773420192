@font-face {
  font-family: "Gilroy-Regular";
  font-display: swap;
  src: url("gilroy-regular.eot");
  src:
    url("gilroy-regular.eot?#iefix") format("embedded-opentype"),
    url("gilroy-regular.woff2") format("woff2"),
    url("gilroy-regular.woff") format("woff"),
    url("gilroy-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  font-display: swap;
  src: url("gilroy-semibold.eot");
  src:
    url("gilroy-semibold.eot?#iefix") format("embedded-opentype"),
    url("gilroy-semibold.woff2") format("woff2"),
    url("gilroy-semibold.woff") format("woff"),
    url("gilroy-semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  font-display: swap;
  src: url("gilroy-light.eot");
  src:
    url("gilroy-light.eot?#iefix") format("embedded-opentype"),
    url("gilroy-light.woff2") format("woff2"),
    url("gilroy-light.woff") format("woff"),
    url("gilroy-light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  font-display: swap;
  src: url("gilroy-bold.eot");
  src:
    url("gilroy-bold.eot?#iefix") format("embedded-opentype"),
    url("gilroy-bold.woff2") format("woff2"),
    url("gilroy-bold.woff") format("woff"),
    url("gilroy-bold.ttf") format("truetype");
}
