$primary-color: #ea404d;
$primary-color-10: #fcebed;
$burgundy: #8b0d38;
$light-blue: #9fb6c8;
$medium-blue: #577d9e;
$deep-blue: #010831;
$gray: #e5e5e5;
$gray-40: rgba(229, 229, 229, 0.4);
$black: #000000;
$white: #ffffff;

$h1-size: 32px;
$h1-line-height: 32px;

$h2-size: 28px;
$h2-line-height: 32px;

$h3-size: 20px;
$h3-line-height: 28px;

$breakpoint-xs: 479px;
$breakpoint-md: 991px;

$colors: (
  primary: $primary-color,
  primary-10: $primary-color-10,
  burgundy: $burgundy,
  light-blue: $light-blue,
  medium-blue: $medium-blue,
  deep-blue: $deep-blue,
  gray: $gray,
  gray-40: $gray-40,
);

@each $name, $color in $colors {
  .bg-#{"" + $name} {
    background-color: #{$color} !important;
  }

  .c-#{"" + $name} {
    color: $color;
  }
}

$shades-of-gray: (
  "0": 0,
  "1": 0.01,
  "2": 0.02,
  "3": 0.03,
  "4": 0.04,
  "5": 0.05,
  "10": 0.1,
  "20": 0.2,
  "30": 0.3,
  "40": 0.4,
  "50": 0.5,
  "60": 0.6,
  "70": 0.7,
  "80": 0.8,
  "90": 0.9,
  "100": 1,
);

@each $shade, $alpha in $shades-of-gray {
  .bg-black-#{$shade} {
    background-color: rgba(black, $alpha);
  }
  .c-black-#{$shade} {
    color: rgba(black, $alpha);
  }
}

.bold {
  font-family: Gilroy-Bold, Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.bold-font-weight {
  font-weight: bolder;
}

.semi-bold {
  font-family: Gilroy-SemiBold, Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.semi-bold-font-weight {
  font-weight: bold;
}

.default-font {
  font-family: Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.light-font {
  font-family: Gilroy-Light, Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
.h1 {
  font-size: $h1-size;
  line-height: $h1-line-height;
  @extend .bold;
}

h2,
.h2 {
  font-size: $h2-size;
  line-height: $h2-line-height;
  @extend .bold;
}

h3,
.h3 {
  font-size: $h3-size;
  line-height: $h3-line-height;
  @extend .bold;
}

.body-large {
  @extend .default-font;
  font-size: 18px;
  line-height: 24px;
}

.body-font {
  @extend .body-large;
  font-size: 18px;
}

.subtext {
  font-size: 11px;
  letter-spacing: 0.6px;
  line-height: 16px;
  color: $black;
}

.small-highlight {
  font-size: 13px;
  letter-spacing: 0.3px;
}

.redcircle-divider {
  border: 0;
  height: 1px;
  background: #000;
  opacity: 0.1;
  margin: 28px 0px;
}

.redcircle-divider.small-margin {
  margin: 12px 0px;
}

a.redcircle-big-link {
  line-height: 16px;
  font-size: 15px;
  color: black;
  text-decoration: none;
  font-weight: bold;
}

a.redcircle-big-link:hover {
  text-decoration: none;
}

button.btn.btn-link {
  text-decoration: none;
  padding: 0;
  margin: 0;
  margin-left: 1ch;
  @extend .bold;
}

button.btn.btn-link:hover {
  text-decoration: none;
}

.btn.btn-primary.btn-small {
  border-radius: 3px !important;
  height: 24px;
  font-size: 13px;
  line-height: 1em;
  padding: 6px 8px;
  min-width: 72px;
  &:active {
    box-shadow: none;
  }
}

.btn.btn-large {
  border-radius: 4px !important;
  height: 48px;
  font-size: 16px;
  line-height: 1.5em;
  padding: 12px;
  min-width: 72px;
}

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: none !important;
}

a.no-style-link,
a.no-style-link:hover,
a.no-style-link:visited,
a.no-style-link:active,
a.no-style-link:link {
  text-decoration: none !important;
  color: inherit !important;
}
