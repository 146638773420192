.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(white, 0.8);
  display: flex;
  justify-content: center;
  z-index: 999;
  padding-top: 24px;
  min-height: 200px;
  -webkit-animation: fadein 0.2s ease-in forwards;
  animation: fadein 0.2s ease-in forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
