.monetization-page__balance-header {
  .btn.btn-primary {
    margin-bottom: 0px;
  }
}

.monetization-page__no-podcasts-opted-in {
  margin-top: 40px;
}

.monetization-page__cash-out-button {
  position: relative;
}

.monetization-page__cut-info {
  font-size: 12px;
  color: #aeaeae;
}
