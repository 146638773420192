@import "redcircle-ui/scss/colors";

.media-file-upload-button__icon {
  margin-right: 10px;
  margin-bottom: 5px;
}

.audio-drop-zone {
  background: $primary-color;
  border-radius: 4px;
  padding: 12px;
}

.audio-drop-zone-text {
  color: $white;
  size: 16px;
  text-align: center;
  margin-bottom: 0px;
  line-height: 20px;
  font-weight: bold;
}

.episode-form__file-indicator {
  @extend .audio-drop-zone-text;
  font-size: 11px;
  line-height: 12px;
}

.audio-drop-zone__help-text {
  color: #999;
  font-size: 10px;
  margin-top: 3px;
}
