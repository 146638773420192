.controls_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.item_container {
  span[class="ant-descriptions-item-content"] {
    width: 100% !important;
  }
}

.graph_message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}
