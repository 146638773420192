@import "redcircle-ui/scss/colors";

$bg-section: #fafafa;
$border-radius: 5px;

.highlight-box {
  min-height: 85px;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 16px;
  &.scalable-height {
    height: auto;
  }

  &-icon {
    height: 12px;
    width: 12px;
    fill: #c6c6c6;
    margin: 0px 0px 0px 2px;
    vertical-align: -12%; // icon tends to not be alinged to surrounding text and appears slightly higher. This helps centering the icon
  }

  &.analytics {
    background: $bg-section;
    mix-blend-mode: normal;
    border: 1px solid $gray-lightest;
    border-left: 4px solid $blue-medium;
    margin: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.bordered {
    height: 100%;
    background: $bg-section;
    border-left: 4px solid $blue-medium;
    border-radius: $border-radius;
  }
}

.highlight-box__stat {
  margin-top: 8px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
