@import "redcircle-ui/scss/media";
@import "redcircle-ui/scss/utility";
@import "src/styles/variables";

.page {
  padding: 38px 24px 38px;
  margin: 0 auto;
  position: relative;
  z-index: $ZINDEX-DEFAULT;

  @include media-breakpoint-down(md) {
    padding: 24px 12px;
  }
  @include media-breakpoint-up(md) {
    max-width: 1072px;
  }
}

.title {
  line-height: 125%;
  margin: 0;
}

.sidebar {
  height: 100vh;
  border-left: 1px solid rgba(0, 0, 0, 0.06);

  :global(.footer) {
    @include box-shadow(3);
  }
}
