@import "redcircle-ui/scss/colors";

// ANTD OVERRIDES
.tri-checkbox {
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: white;
    border: 1px solid #c6c6c6;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid $primary-color;
    border-top: 0;
    border-left: 0;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    content: "\2014";
    background-color: white;
    color: $primary-color;
  }
}
