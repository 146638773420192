@import "redcircle-ui/scss/media";
@import "src/styles/utility";

$border-radius: 5px;
$bg-section: #fafafa;
$color-error: #a94442;

$tags: (
  "draft": (
    color: #0c6cbb,
    background: #daf1ff,
  ),
  "vetting": (
    color: #805c20,
    background: #ffe7be,
  ),
  "awaiting-script": (
    color: #995117,
    background: #ffdbbe,
  ),
  "awaiting-audio-upload": (
    color: #5f3eb2,
    background: #e4d9ff,
  ),
  "audio-swap-requested": (
    // audio swap looks like paused
    color: #ea404d,
    background: #ffe0e1,
  ),
  "running": (
    color: #417505,
    background: #edf2e7,
  ),
  "completed": (
    color: #417505,
    background: #edf2e7,
  ),
  "sent": (
    color: #805c20,
    background: #ffe7be,
  ),
  "paused": (
    color: #ea404d,
    background: #ffe0e1,
  ),
  "expired": (
    color: #979797,
    background: #f2f2f2,
  ),
  "declined": (
    color: #979797,
    background: #f2f2f2,
  ),
  "canceled": (
    color: #979797,
    background: #f2f2f2,
  ),
);

@each $name, $set in $tags {
  .tag-#{$name} {
    color: map-get($set, color) !important;
    background: map-get($set, background) !important;
  }
}

// override special case for podcaster tag with "awaiting audio upload" and "awaiting script"
.podcaster {
  &.tag-awaiting-audio-upload {
    color: #996236 !important;
    background: #ffdbbe !important;
  }
  &.tag-awaiting-script {
    color: #5f3eb2 !important;
    background: #e4d9ff !important;
  }
}

.campaign-page {
  .campaign-cart {
    background: $bg-section;
    border-radius: $border-radius;

    @include media-breakpoint-down(sm) {
      .campaign-cart-cta {
        flex-flow: column;
      }
    }

    .text-subtle {
      text-align: center;
    }
  }

  .campaign-tabs {
    overflow: visible;
  }
}

.campaign-dashboard-table,
.advertising-tables {
  .ant-table-thead th:last-child {
    text-align: end;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
  }
  .ant-tabs-ink-bar {
    background: black;
  }

  .campaign-table-cell {
    display: flex;
    align-items: center;
    a {
      color: inherit;
    }
  }

  button.expanded {
    transform: rotate(180deg);
  }
  .money-tag {
    position: relative;
    fill: #577d9e;
    height: 13px;
    width: 13px;
    min-width: 13px;
    min-height: 13px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .disabled {
    opacity: 0.5;
  }
  .end-date {
    color: $color-error;
    text-transform: capitalize;
  }
  .cta {
    margin-left: 1.5rem;
    min-width: 132px;
    button {
      width: 100%;
    }
  }
  .context {
    text-align: end;
    justify-content: flex-end;
  }
  .due-date {
    text-align: end;
    white-space: nowrap;
  }
  .discrete-icon {
    min-width: 16px;
  }
}

.table-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $bg-section;
  border-radius: $border-radius;
  text-align: center;
}

.script-content {
  font-size: 16px;
  p,
  ul,
  ol,
  li {
    @include set-font($font-family: "Regular", $font-size: 16px, $line-height: 20px);
    margin: 0px 0px 2px 0px;
  }
}

.RC-collapse-v-align {
  div.ant-collapse-header {
    display: flex !important;
    align-items: center !important;
  }
}
