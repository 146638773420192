.list-view-row:hover {
  text-decoration: none;
  a:hover {
    text-decoration: none;
  }
}

.list-view-row__main-text.redcircle-big-link {
  line-height: 20px;
  vertical-align: middle;
  margin-bottom: 2px;
}

.list-view-row__sub-text.subtext:hover {
  text-decoration: none;
  color: black;
}
.list-view-row {
  vertical-align: middle;
  cursor: pointer;
  height: 56px;
  display: flex;
}
.list-view-row__text-wrapper {
  width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  height: 100%;
}

.list-view-row {
  .dropdown.btn-group,
  .dropup.btn-group {
    display: block;
    float: right;
    position: relative;
    top: 12px;
  }
}
