@import "redcircle-ui/scss/colors";

.radio-select-frame {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-height: 148px;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 4px solid rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  box-shadow: 0px 4px 36px rgba(159, 182, 200, 0.2);
  border-radius: 8px;

  .radio-select-frame__input {
    margin-left: 36px;
    margin-top: -12px;
  }
  .radio-select-frame__text {
    margin-left: 36px;
    margin-right: 36px;
  }
  &.radio-select-frame__disabled {
    color: #979797;
    h3 {
      color: #979797;
    }

    cursor: default;
  }
}

.radio-select-frame__selected {
  border-left: 4px solid $primary-color;
}
