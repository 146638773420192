@import "redcircle-ui/scss/colors";

.browse-promotions-page__category-wrapper {
  width: 100%;
}

.browse-promotions-page__category-item {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.browse-promotion-search__wrapper {
  top: 0;
  left: -10px;
  right: -10px;
  bottom: 0;
  position: absolute;
  background: white;
  z-index: 100000000000;
}

.detailed-search-row {
  margin-bottom: 16px;
  margin-top: 0px;
}

.browse-promotion-search__search-result {
  height: 48px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin-bottom: 8px;
  line-height: 40px;
  display: flex;
}

.browse-promotion-search__title {
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.browse-promotion-search__spacer {
  flex: 1;
}

.browse-promotion-search__search-picture {
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: cover;
  background: $gray-lighter;
  background-size: cover;
  margin-right: 16px;
}

.browse-promotions-search__arrow {
  color: $gray-lighter;
  line-height: 40px !important;
  position: relative !important;
  top: -1px !important;
}

.browse-search-enter {
  opacity: 0;
  transition: all ease-in-out 500ms;
}
.browse-search-enter-active {
  opacity: 1;
  transition: all ease-in-out 500ms;
}

.browse-search-exit-active {
  opacity: 0;
  transition: all ease-in-out 500ms;
}

.browse-promotions-page__browse-row {
  margin-top: 25px;
}

.browse-promotions-page__browse-row-wrapper {
  min-height: 200px;
  position: relative;
}

.browse-promotions-page__browse-row-wrapper .browse-promotions-page__browse-row:first-child {
  margin-top: 50px;
}

.browse-promotions-page__title {
  float: left;
  margin: 0;
}
.browse-promotions-page__search-button {
  line-height: 32px;
  float: right;
  cursor: pointer;
}

.browse-promotions-page__categories-title {
  margin-top: 68px;
  margin-bottom: -4px;
}

.category-column a {
  border-bottom: 1px solid $gray-lighter;
}

.chevron--right.browse-promotions-page__category-arrow {
  position: relative;
  top: -1px;
}

.browse-promotions-page__category-wrapper .category-row:first-child {
  margin-top: -12px;
}

.browse-promotion-search__title {
  margin-bottom: 0px;
  margin-top: 0px;
}

.browse-promotion-search__close {
  cursor: pointer;
  color: $primary-color;
  line-height: 32px;
}

.browse-promotion-search__close-wrapper {
  text-align: right;
}

.btn.browse-promotion-search__filter-button {
  width: 100%;
  min-width: 100%;
  height: 48px;
  outline: 0;
  background-image: url("../../../icons/filter.svg");
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 !important;
}

.btn.browse-promotion-search__filter-button:focus,
.btn.browse-promotion-search__filter-button:hover,
.btn.browse-promotion-search__filter-button:active,
.btn.browse-promotion-search__filter-button.active {
  outline: 0 !important;
  background-image: url("../../../icons/filter.svg");
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: none;
}

.btn.browse-promotion-search__filter-button:focus {
  background-color: transparent;
}

.browse-promotion-search__search-box.form-control {
  padding-left: 40px;
  background-image: url("../../../icons/search.svg");
  background-repeat: no-repeat;
  background-position-y: 11px;
  background-position-x: 7px;
  margin-bottom: 16px;
}

.browse-promotion-search__no-results {
  font-size: 18px;
  margin-top: 24px;
}
