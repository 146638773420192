.chevron {
  display: inline-block;
  width: 16px;
  height: 20px;
  margin-left: 8px;
}

.chevron--right {
  @extend .chevron;
  background-image: url("../icons/forward-chevron.svg");
  position: relative;
  top: 3px;
}
