@import "redcircle-ui/scss/utility";

$MAX_WIDTH_DEFAULT: 992px;
$MAX_WIDTH_LARGE: 1400px;
$MAX_WITH_XLARGE: 1600px;

.modal {
  :global(.ant-modal-content) {
    padding: 0;
  }
}

// DEFAULT SPACING
.title,
.sidebar {
  padding-top: 48px;
}
.title,
.body,
.footer {
  padding-left: 24px;
  padding-right: 24px;
}
.title-inner,
.body-inner,
.footer-inner {
  width: 100%;
  max-width: $MAX_WIDTH_DEFAULT;
  margin: 0 auto;
}
.footer {
  padding-top: 24px;
  padding-bottom: 24px;
}

// COMPONENTS
.body {
  padding-bottom: 24px;
  &.md,
  &.lg,
  &.xl {
    min-height: 400px;
  }
}
.footer-border {
  @include box-shadow(5);
}
.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close * {
  fill: #666;
}

// SIZING
.xs,
.sm {
  padding-left: 64px;
  padding-right: 64px;
}
.lg {
  .title-inner,
  .body-inner,
  .footer-inner {
    max-width: $MAX_WIDTH_LARGE;
  }
}
.xl {
  .title-inner,
  .body-inner,
  .footer-inner {
    max-width: $MAX_WITH_XLARGE;
  }
}

// OPTIONS
.viewport-height {
  &,
  :global(.ant-modal-content),
  :global(.ant-modal-body) {
    height: 95vh;
    max-height: 95vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .body {
    flex: 1;
    overflow-y: auto;
    min-height: auto;
  }
  .body-inner {
    height: 100%;
  }
  .header,
  .footer {
    flex-shrink: 0;
  }
}
