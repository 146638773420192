@import "redcircle-ui/scss/redcircle";

@import "src/styles/utility";
@import "src/styles/typography";
@import "src/styles/tooltip";

$logo: url(../images/logo-redcircle-dark.svg);

// OVERRIDES =======================================================================================

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family:
    DM Sans,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

a.disabled-link,
a[disabled] {
  pointer-events: none;
}
a.no-style-link,
a.no-style-link:hover,
a.no-style-link:visited,
a.no-style-link:active,
a.no-style-link:link {
  text-decoration: none !important;
  color: inherit !important;
}

a:hover {
  text-decoration: none;
}

label {
  font-weight: normal !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// LIBRARY OVERRIDES ===============================================================================

.grecaptcha-badge {
  visibility: hidden;
}

.quill-editor {
  height: 100%;

  p {
    margin-bottom: 10px;
  }

  div {
    background-color: inherit;
  }

  .ql-tooltip {
    left: 0 !important;
  }

  .ql-editor {
    overflow-wrap: anywhere; // "anywhere" is preferred over "break-word" due to possibility of long URLS
    word-break: normal;
  }
}

// MAIN WRAPPER ====================================================================================

.main-content {
  flex: 1;
  margin-left: 250px;
  transition: all 0.3s;
  position: relative;
}

.main-wrapper--logged-in .main-content {
  &:not(.main-content--hidden-sidebar) {
    @include media-breakpoint-down(md) {
      padding-top: 60px;
    }
  }
}

.main-content--no-sidebar {
  margin-left: 0px;
}

.main-content .sidebar-menu-button {
  position: relative;
}

.main-content.main-content--no-sidebar .sidebar-menu-button {
  display: block;
}

@include media-breakpoint-down(md) {
  .main-content {
    margin-left: 0;
  }
}

.mobile-top-bar {
  display: none;
}

@include media-breakpoint-down(md) {
  .mobile-top-bar {
    display: block;
    position: fixed;
    top: 0;
    height: 60px;
    left: 0;
    right: 0;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    background: white;
    z-index: 998; // less than .main-sidebar
  }
  .hamburger-wrapper {
    cursor: pointer;
    height: 60px;
    line-height: 60px;
    padding: 0px 15px;
    font-size: 24px;
    float: left;
  }
  .hamburger-wrapper .glyphicon {
    position: relative;
    top: 4px;
  }
  .sidebar-menu-button__logo {
    position: absolute;
    display: block;
    top: -1px;
    left: 40px;
    width: 200px;
    background: $logo;
    background-size: 100% auto;
    height: 60px;
    background-repeat: no-repeat;
    z-index: 10000;
  }
}

// UTILITY =========================================================================================

.modal-dialog {
  margin: 10px auto !important;
}
.modal-body {
  min-height: 100px;
}

.text-disabled {
  opacity: 0.2;
}

.semi-transparent {
  opacity: 0.5;
}

.pointer-events-none {
  pointer-events: none;
}

.bg-mblue {
  background: rgba($blue-medium, 0.1);
}

.op-10 {
  opacity: 0.1;
}

.line-height-1em {
  line-height: 1em;
}

.btn.btn-link.seedcast-btn-link {
  background: none;
  color: inherit;
  font-weight: normal;
  margin-left: 0px;
  font-size: inherit;
  width: auto;
  min-width: auto;
  outline: none;
}

.btn.btn-link.seedcast-btn-link:active {
  background: none;
  outline: none;
  box-shadow: none;
}

.btn.btn-link.seedcast-btn-link:hover {
  text-decoration: underline;
}

.btn.btn-small {
  border-radius: 3px !important;
  height: 24px;
  font-size: 13px;
  line-height: 1em;
  padding: 4px 8px !important;
  min-width: 72px;
  &:active {
    box-shadow: none;
  }
}

.seedcast-label {
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 1.5em;
}

.align-baseline {
  align-items: baseline;
}

.align-center-self {
  align-self: center;
}

.align-end-self {
  align-self: flex-end;
}

.fake-link {
  color: $primary-color;
  font-weight: bold;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.black-text {
  color: black;
}

.btn {
  a {
    color: white;
    &:hover {
      color: white;
    }
  }
}

.pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-corners-large {
  border-radius: 16px;
}

.rounded-corners {
  border-radius: 4px;
}

/*
  Chrome hides scroll bars when you do overflow: scroll. This is a workaround for that.
  https://stackoverflow.com/a/35203104
 */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.h4 {
  @include set-font(
    $font-size: 13px!important,
    $line-height: 1.85em!important,
    $letter-spacing: 0.046em
  );
}

.svg-button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  &:hover svg * {
    fill: $primary-color;
    transition: fill 0.5s;
  }
}
