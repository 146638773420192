@import "src/styles/utility";
@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";

.TextSwitch {
  display: flex;
  flex-direction: row;

  &.pill {
    height: 24px;
    border: 1px solid $blue-medium;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .TextSwitch--option {
      @include set-font("Bold", $font-size: 15px!important, $line-height: 1.6em!important);
      background-color: white;
      color: $blue-medium;
      cursor: pointer;
      text-align: center;

      height: 100%;
      padding: 0px 0.5em;

      flex: 1 0 auto;

      transition:
        0.3s color ease-in-out,
        0.3s background-color ease-in-out;

      &.selected {
        background-color: $blue-medium;
        color: white;
      }
    }
  }

  &.tab {
    min-height: 32px;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    .TextSwitch--option {
      position: relative;
      @include set-font("Bold", $font-size: 13px!important, $line-height: 1.5em!important);
      background-color: white;
      border: 1px solid $gray-lightest;
      border-bottom: 0.5px solid $gray-lightest;
      color: black;
      cursor: pointer;

      padding: 10px 20px 10px 25px;
      flex: 1 0 50%;
      @include media-breakpoint-up(md) {
        flex: 0 0 auto;
        @include set-font("Bold", $font-size: 15px!important, $line-height: 1.6em!important);
      }
    }

    .TextSwitch--option.hidden {
      display: none;
    }

    .TextSwitch--option::after {
      transition:
        background-color 0.2s ease-in-out,
        min-height 0.1s ease-in-out;
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      min-height: 1px;
      width: 100%;
      background-color: $gray-lightest;
    }

    .TextSwitch--option::before {
      transition: background-color 0.2s ease-in-out;
      content: "";
      position: absolute;
      bottom: 35%;
      left: 10px;
      width: 5px;
      height: 40%;
    }

    .TextSwitch--option:nth-child(2)::before {
      background-color: scale-color(#ff9ca0, $alpha: 100%);
    }
    .TextSwitch--option:nth-child(3)::before {
      background-color: scale-color(#ff9ca0, $alpha: -30%);
    }
    .TextSwitch--option:nth-child(4)::before {
      background-color: scale-color(#ff9ca0, $alpha: -60%);
    }
    .TextSwitch--option:nth-child(5)::before {
      background-color: scale-color(#ff9ca0, $alpha: -90%);
    }

    .selected.TextSwitch--option::after {
      min-height: 6px;
      background-color: $primary-color;
    }
  }
}
