@import "redcircle-ui/scss/colors";

.drop-zone-box {
  width: 100%;
  max-width: 308px;
  position: relative;
}

.drop-zone-box:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.drop-zone-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $gray-transparent;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.audio-drop-zone-content {
  cursor: pointer;
}

.drop-zone-title {
  text-align: center;
}

.drop-zone-input {
  display: block !important;
  position: fixed;
  left: -100000px;
}
.drop-zone-preview {
  max-width: 72px;
  max-height: 72px;
  position: absolute;
  left: -92px;
  top: 28px;
}

.uploaded-image {
  width: 308px;
  height: 308px;
  overflow: hidden;
}
.image-upload-icon {
  height: 50px;
}
.image-upload__size-info {
  font-size: 90%;
  width: 80%;
  text-align: center;
  margin: 12px auto;
}
