@import "redcircle-ui/scss/colors";

.button {
  width: 100%;
  position: relative;
  overflow: hidden;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.04);
  padding: 70px 70px;
  box-shadow: 0px 4px 36px rgba(159, 182, 200, 0.2);
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  span {
    text-align: left;
    color: black;
  }
}

.rotated-background {
  position: absolute;
  top: -100px;
  width: 300px;
  height: 500px;
  transform: rotate(30deg);
  right: -50px;
  opacity: 0.1;
}

.arrow svg {
  fill: $primary-color;
}
