.browse-row__row {
  min-height: 125px;
  position: relative;
}

h3.browse-row__title {
  margin-bottom: -8px;
}

.browse-row-divider {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
