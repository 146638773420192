@import "redcircle-ui/scss/colors";

.album-art-component {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  &.small {
    width: 48px;
    height: 48px;
  }
  &.medium {
    width: 64px;
    height: 64px;
  }

  &.redirected {
    opacity: 0.4;
  }
}

.album-art-wrapper a {
  text-align: center;
  display: block;
  height: 100%;
  width: 100%;
}

.album-art-title a {
  margin-top: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.album-art-subtitle {
  text-align: left;
  font-size: 13px;

  a {
    text-align: left;
  }
}

.album-grid-clearer {
  margin-bottom: 40px;
}
