.enable-subscriptions {
  .body {
    min-height: 600px;
  }
  .description {
    margin-bottom: 40px;
  }
  .footer-container {
    padding-top: 22px;
  }
  .inline-form-checkbox {
    display: inline;
    label {
      padding: 0;
    }
    input[type="checkbox"] {
      margin: 0;
    }
  }
}
