.enable-subscriptions {
  .enable-subscriptions-main-content {
    min-height: 300px;
  }
  .enable-subscriptions__album-art-wrapper {
    margin-bottom: 50px;
  }

  .exclusive-content-intro-text {
    margin-bottom: 100px;
  }

  .exclusive-content-row {
    border-bottom: 1px solid rgba(black, 0.1);
    position: relative;
    margin-bottom: 100px;
    padding-top: 4px;
    .redcircle-badge {
      display: inline-block;
      margin-left: 20px;
      position: relative;
      top: -2px;
      left: -8px;
    }
    .context-menu {
      position: absolute;
      right: 10px;
      top: 0;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
  .gray-box {
    border-radius: 4px;
    background: rgba(black, 0.02);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 84px;
    font-weight: bold;
    .title {
      text-transform: uppercase;
      font-size: 11px;
      margin-bottom: 8px;
    }
  }

  padding: 10px 0;
  .disabled-content {
    text-align: center;
  }
}
