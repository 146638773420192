@import "redcircle-ui/scss/colors";

button.btn-link.btn.import-form__rss-feed-switch {
  color: $primary-color;
  text-decoration: none;
  background: none !important;
  box-shadow: none;
  outline: none;
  font-weight: normal;
  margin-left: 0px;
  &:hover {
    color: $burgundy;
    text-decoration: underline;
  }
}

.feed-import-form__show-search .form-group,
.feed-import-form__feed-url .form-group {
  margin-bottom: 0px;
}

.import-modal__submit-button {
  width: 100%;
  margin-top: 24px;
}

.btn-primary.import-modal__submit-button {
  margin-top: 25px;
}

.import-podcast-header {
  margin-bottom: 0px;
}

.form-control.import-podcast-checkbox {
  height: 20px !important;
  width: 20px !important;
  margin-right: 5px !important;
  display: inline-block;
  margin-top: 0px;
  position: relative;
  top: 5px;
}
.directory-show-image,
.loading-image__loader.directory-show-image {
  width: 50px;
  margin-right: 10px;
  height: 50px;
}

.are-you-sure {
  &-button {
    &:disabled {
      color: #979797;
      background-color: #fafafa !important;

      &:hover {
        color: #979797;
      }
    }
  }

  &-optional-amount {
    padding-left: 28px;
    margin-bottom: 8px;

    &__label {
      margin-right: 10px;
    }

    &__input {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
