$border-radius: 5px;
$bg-section: #fafafa;

.block {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: $bg-section;
  border-radius: $border-radius;
  text-align: center;
}
