@import "redcircle-ui/scss/colors";

.stripe-form {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  .spacer {
    flex: 1;
  }
  .card-form {
    height: 80vh;

    overflow-y: auto;
    overflow-x: hidden;
  }
  .card-selector {
    height: 84px;
    border: 2px solid $primary-color;
    box-shadow: 0 4px 12px 0 rgba(234, 64, 77, 0.12);
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 16px;
    .card-description {
      text-transform: uppercase;
      opacity: 0.5;
      font-size: 11px;
      color: #000000;
      letter-spacing: 0.6px;
      line-height: 16px;
    }
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before {
      top: 14px;
    }
    [type="radio"]:checked + label:after {
      top: 18px;
    }
  }
}
.stripe-add-card-view {
  input,
  .StripeElement:not(.PaymentRequestButton) {
    display: block;
    border: 1px solid rgba(black, 0.4);
    border-radius: 4px;
    height: 46px;
    padding: 0 12px;
    margin-bottom: 20px;
    margin-top: 3px;
    font-size: 20px;
  }
  label {
    font-size: 11px;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: Gilroy-SemiBold, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
  }
  .or-divider {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .space {
      flex: 1;
      height: 1px;
      background: rgba(black, 0.1);
    }
    .or {
      font-size: 20px;
      width: 40px;
      text-align: center;
    }
  }
}
