@import "redcircle-ui/scss/media";

.show-campaign-page {
  .discrete-table {
    margin: 0 -24px -24px -24px;
    margin-bottom: -24px !important;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  .show-campaign-table {
    overflow: visible;
  }

  .show-campaign-media-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 0 1rem;
    margin-top: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .cta {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
