@import "redcircle-ui/scss/colors";

$font-path: "~react-widgets/lib/fonts";
$img-path: "~react-widgets/lib/img";

$multiselect-tag-color: $white !default;
$multiselect-tag-border: $primary-color !default;
$multiselect-tag-bg: $primary-color;

@import "~react-widgets/lib/scss/react-widgets";

// weird amount of css to overwrite the default to get it centered and spaced correctly
.rw-multiselect-tag {
  padding-top: 2px;
  vertical-align: middle;
  margin-top: 0;
  font-size: 12px;
}
.show-form__tag-item {
  color: $white;
  font-size: 0.8em;
}

%show-form__buttons {
  width: 100%;
  margin-bottom: 0 !important;
  margin-top: 24px;
}

.show-form__submit-button {
  @extend %show-form__buttons;
}

.show-form__cancel-button {
  @extend %show-form__buttons;
  position: relative;
  top: -1px;
}

.show-form__footer {
  position: absolute;
  bottom: -111px;
  height: 96px;
  max-width: 960px;
  left: 0;
  right: 0;

  @media screen and (max-width: 1000px) {
    bottom: -113px;
  }
}

.rw-multiselect .rw-input-reset,
.rw-multiselect .rw-input,
.rw-multiselect .rw-dropdown-list-autofill,
.rw-multiselect .rw-filter-input {
  height: 48px !important;
}

input.rw-widget-input.rw-input {
  color: $black;
}

div.rw-widget-input.rw-widget-picker.rw-widget-container {
  box-shadow: none;
}
