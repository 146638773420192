// brand colors
$red: #ed2924;
$charcoal: #191919;
$gray-dark: #6a6a6a;
$gray: #a3a3a3;
$gray-light: #d9d9d9;
$gray-lighter: #f5f5f5;
$gray-lightest: #e5e5e5;
$gray-transparent: rgba(229, 229, 229, 0.4);

// supporting colors
$red-dark: #d62520;
$red-light: #f94e47;
$red-lighter: #fcebed;
$burgundy: #8b0d38;
$blue-dark: #010831;
$blue-medium: #577d9e;
$blue-light: #9fb6c8;
$blue-lighter: #eff3f6;
$black: #000d0e;
$black-smoky: #190a05;
$offwhite: #fafafa;
$white: #ffffff;
$white-anti-flash: #ecf1f4;
$yellow: #faad14;
$yellow-dark: #d48806;
$green: #a0d911;
$green-dark: #5b8c00;

// semantic colors
$primary-color: $red;
$primary-color-dark: $red-dark;
$primary-color-light: $red-light;

// Text Colors
.color-inherit {
  color: inherit;
}
.color-white {
  color: $white;
}
.color-primary {
  color: $primary-color;
}
.color-primary-dark {
  color: $primary-color-dark;
}
.color-error {
  color: $burgundy;
}
.color-success {
  color: $green;
}
.color-success-dark {
  color: $green-dark;
}
.color-warning {
  color: $yellow;
}
.color-warning-dark {
  color: $yellow-dark;
}
.color-gray-dark {
  color: $gray-dark;
}
.color-gray {
  color: $gray;
}
.color-gray-light {
  color: $gray-light;
}
.color-charcoal {
  color: $charcoal;
}

// Background Colors

.bg-primary-dark {
  background-color: $primary-color-dark;
}
.bg-dark {
  background-color: $charcoal;
}
.bg-gray {
  background-color: $gray;
}
.bg-gray-light {
  background-color: $gray-light;
}
.bg-gray-lighter {
  background-color: $gray-lighter;
}
.bg-gray-lightest {
  background-color: $gray-lightest;
}
.bg-gray-transparent {
  background-color: $gray-transparent;
}
.bg-offwhite {
  background-color: $offwhite;
}
.bg-white {
  background-color: $white;
}
.bg-black {
  background-color: $black;
}
.bg-black-smoky {
  background-color: $black-smoky;
}

// Borders

.border-underline {
  border-bottom: 1px solid $gray;
}
.border-underline-light {
  border-bottom: 1px solid $gray-light;
}
