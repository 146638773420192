.stats-widget {
  margin-top: 75px;
}

.stats-widget.stats-widget--no-stats {
  margin-top: 0px;
}

.stats-widget-inner {
  position: relative;
  min-height: 400px;
}

.stats-widget__divider {
  margin-bottom: 0px;
}

.stats-widget-graph-title {
  height: 28px;
}

.stats-widget-graph-title--with-form-control {
  width: 30%;
  overflow: visible;
  float: left;
}

.stats-widget-graph-title h3 {
  margin: 0;
}

.downloads-graph__time-selector {
  float: right;
}
.downloads-graph__time-selector select {
  float: right;
  height: 30px;
  position: relative;
  top: -2px;
}

.stats-widget-graph-container {
  height: fit-content;
  padding: 20px 28px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
}

.stats-widget-graph-container-with-height {
  @extend .stats-widget-graph-container;
  height: 256px;
}

.stats-widget__graphs-wrapper {
  margin: 32px 0px;
}

.stats-widget-graph {
  width: 100%;
  position: relative;
}

.stats-width-graph-height {
  height: 180px;
}

.stats-widget-app-graph {
  display: flex;
  height: 100%;
}
.stats-widget-app-graph__list {
  flex: 1;
  max-width: 200px;
}
.stats-widget-app-graph__pie {
  flex: 1;
  position: relative;
}

.stats-widget-app-graph__pielabel {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  display: table;
}

.stats-widget-app-graph__pielabel__inner {
  display: table-cell;
  vertical-align: middle;
}

.stats-widget-app-graph__list {
  font-size: 15px;
  line-height: 24px;
  margin-top: 24px;
}

.stats-widget-app-graph__list ol {
  padding: 0;
}

.stats-widget-app-graph__list ol li {
  list-style: none;
  margin-bottom: 8px;
}

.stats-widget-app-graph__list ol li .app-list-label {
  margin-right: 8px;
  font-weight: bold;
}

.device-graph {
  width: 100%;
  height: 100%;
}

.device-graph__circles_row,
.device-graph__labels-row {
  width: 100%;
  height: 125px;
  display: flex;
}

.device-graph__labels-column__percent {
  font-size: 20px;
  font-weight: bold;
}

.device-graph__labels-row {
  height: 32px;
  text-align: center;
}

.device-graph__circles-column,
.device-graph__labels-column {
  width: 25%;
  height: 100%;
  flex: 1;
  position: relative;
}
