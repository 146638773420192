@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/utility";

.dropdown-link {
  background: white;
  border: 1px solid $gray-lightest;
  padding: 6px 8px 6px 12px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: small;
  color: #595959;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  & > *:first-child {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > *:last-child {
    flex: 0;
  }
}

.dropdown-menu {
  padding: 0 !important;
  overflow: hidden;
}

.dropdown-menu-body {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1rem 1rem;

  a {
    color: inherit;
  }
}

.dropdown-menu-option {
  padding: 0.5rem 0;
}

.dropdown-menu-footer {
  @include box-shadow(5);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.check {
  color: $primary-color !important;
  padding-right: 5px;
}

.spacer {
  padding-left: 20px;
}
