@import "redcircle-ui/scss/media";

.update-tier-page-wrapper {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }
  .plan-card {
    margin-bottom: 24px;
  }
}
