// nextjs uses next/font to preload fonts, so we don't need to include this in
// this file is used for storybook and other non-nextjs apps
@import "../fonts/DMSans/stylesheet.scss";
@import "../fonts/Poppins/stylesheet.scss";

html {
  font-family:
    "DM Sans",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6,
.subtitle1,
.subtitle3 {
  font-family: "Poppins", sans-serif;
}
