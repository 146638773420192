@import "src/styles/utility";

.add-script-modal {
  .redcircle-form-label.control-label {
    color: #000000 !important;
    @include set-font(Bold, $font-size: 11px, $line-height: 16px, $letter-spacing: 1px);
  }

  .ql-container {
    $minQuillHeight: 80px;

    min-height: $minQuillHeight;
    .ql-editor {
      min-height: $minQuillHeight;
    }
  }
}
