@import "./media";
@import "./colors";
@import "./spacing";

h1,
h2,
h3,
h4,
h5,
h6,
.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6 {
  line-height: 1.1;
}
.subtitle1,
.subtitle2,
.subtitle3 {
  line-height: 1.2;
}

// UTILITIES
.font-size-xl {
  font-size: 2rem;
}
// body1
.font-size-lg {
  font-size: 1.125rem;
}
.font-size-md {
  font-size: 1rem;
}
// body3
.font-size-sm {
  font-size: 0.75rem;
}

.text-left {
  text-align: left;
}
.text-start {
  text-align: start;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-right {
  text-align: right;
}

.preline {
  white-space: pre-line;
}
.list-style-none {
  list-style: none;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}

.reset-font-size {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem !important;
  }
}
.word-wrap {
  word-break: break-word;
}
.no-wrap {
  white-space: nowrap;
}
