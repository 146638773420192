@import "redcircle-ui/scss/colors";

.script-item-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;

  & li,
  & p {
    margin: 0px;
    padding: 0px;
  }
}

.antd-collapse-override {
  .ant-collapse-header {
    display: flex;
    flex-direction: row;
    padding-left: 0px !important;

    span.ant-collapse-arrow {
      order: 2;
      padding-left: 10px;
      color: $primary-color;
    }

    span.primary-color.bold {
      order: 1;
    }
  }
}
