.deal-request-form-content {
  min-height: 400px;
  padding-bottom: 50px;

  .form-step-dots {
    position: absolute;
    bottom: 0px;
  }
}

.deal-reaquest-form__buttons .btn {
  width: 100%;
}

.deal-reaquest-form__buttons .btn.btn-primary {
  position: relative;
  top: 1px;
}

.deal-request-form__dots {
  margin-top: 28px;
  margin-bottom: 28px;
}
