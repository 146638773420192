@import "./generated/css/bootstrap.css";
@import "./generated/css/bootstrap-theme.css";
@import "./fonts/font-family.css";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import "./scss/redcircle";
// @import "./scss/spacing";
@import "./scss/forms";
// @import "./scss/images";
