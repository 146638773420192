@import "src/styles/utility";
@import "redcircle-ui/scss/media";

.InfoCard {
  border: 1px solid #f0f0f0;
  margin: 0;
  padding: 24px;
  position: relative;
  background: #fff;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: capitalize;
  }

  &-tooltip {
    max-width: 230px;
    border-radius: 4px;
    padding: 3px 8px;
    text-transform: initial;
  }

  &-responsiveRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > * {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    &.reverse {
      flex-direction: column-reverse;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;

      & > * {
        margin: 0px;
      }
    }
  }
}
