@import "redcircle-ui/scss/colors";
$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);

.info-tooltip {
  top: 2px !important;
  left: 8px;
}

.info-tooltip.info-tooltip--no-top-bump {
  top: 0px !important;
}

[class^="tooltip"] {
  position: relative;
  &:after {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    content: attr(data-tooltip);
    padding: 6px 10px;
    top: 1.4em;
    left: 50%;
    transform: translateX(-50%) translateY(-2px);
    background: $primary-color;
    color: white;
    min-width: 200px;
    z-index: 2;
    border-radius: 2px;
    transition: opacity 0.2s $cubic, transform 0.2s $cubic;
  }
  &:hover {
    &:after {
      display: block;
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) translateY(0);
    }
  }
}

.tooltip--left {
  &:after {
    top: -4px;
    left: 0;
    transform: translateX(-112%) translateY(0);
  }
  &:hover {
    &:after {
      transform: translateX(-110%) translateY(0);
    }
  }
}

.tooltip--right {
  &:after {
    top: -4px;
    left: 100%;
    transform: translateX(12%) translateY(0);
  }
  &:hover {
    &:after {
      transform: translateX(10%) translateY(0);
    }
  }
}

.tooltip--triangle {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    border-bottom: solid 5px grey;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: translateX(-50%) translateY(-2px);
    top: 1.1em;
    left: 50%;
    transition: opacity 0.2s $cubic, transform 0.2s $cubic;
    z-index: 3;
  }
  &:hover {
    &:before {
      display: block;
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) translateY(0);
    }
  }
}

.tooltip-overlay {
  white-space: normal;
  overflow: hidden;
  text-align: left;
  max-width: 250px;
  line-height: 1.5em;
}
