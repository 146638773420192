@import "redcircle-ui/scss/colors";

.page-3-container {
  .table-row {
    align-items: center;
    border-bottom: 1px solid rgba(black, 0.1);
    height: 60px;
  }
  .left-column {
    font-size: 18px;
  }
  .brand {
    font-size: 15px;
    font-weight: bold;
  }
  .last4 {
    font-weight: bold;
    text-align: right;
    opacity: 0.6;
    font-size: 11px;
    color: black;
    letter-spacing: 0.6px;
  }
  .amount {
    font-size: 18px;
    line-height: 24px;
  }
  .info {
    text-align: center;
  }
  .footer {
    width: 100%;
    position: absolute;
    top: 635px;
  }
}
