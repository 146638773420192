@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";

$card-top_border-color: #ebf0f1;

.plan-card {
  box-shadow:
    0px 100px 80px rgba(87, 125, 158, 0.07),
    0px 41.7776px 33.4221px rgba(87, 125, 158, 0.0503198),
    0px 22.3363px 17.869px rgba(87, 125, 158, 0.0417275),
    0px 12.5216px 10.0172px rgba(87, 125, 158, 0.035);
  background: $gray-transparent;
  padding: 32px;
  width: 288px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  overflow-x: clip;
  position: relative;
  align-items: center;

  &.popular {
    border: 4px solid $primary-color;
    margin-top: 20px;

    .circle {
      display: flex;
      justify-content: center;
      padding-top: 6.5%;
      font-weight: 700;
      content: "POPULAR";
      color: $white;
      position: absolute;
      width: 80%;
      aspect-ratio: 1;
      background-color: $primary-color;
      border-radius: 50%;
      left: 10%;
      top: 0%;
      transform: translateY(-20%);
      z-index: -2;
    }
  }

  .save-pill {
    background: $gray-lightest;
    border-radius: 20px;
    padding: 5px 8px;
    white-space: nowrap;
    font-size: 8px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  .title {
    color: $primary-color;
  }

  .popular {
    background: $primary-color;
    position: absolute;
    font-weight: bold;
    color: white;
    transform: rotate(45deg);
    right: -25px;
    text-align: center;
    top: 20px;
    width: 113px;
    opacity: 1;
    font-size: 13px;
    line-height: 16px;
  }
  .monthly {
    font-size: 8px;
    line-height: 1.25em;
  }
  .description {
    max-width: 100%;
  }
  .features {
    padding-left: 12px;
    font-size: 12px;
    line-height: 2em;
    letter-spacing: 0.6px;
  }
  .price {
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
    margin-top: 16px;
  }

  .savings {
    color: $blue-medium;
    font-size: 11px;
  }
  .learn-more {
    font-size: 8px;
    line-height: 12px;
    a {
      color: $blue-medium !important;
    }
  }
  .free-trial {
    position: absolute;
    bottom: -20px;
    left: calc(50% - 62px);
  }
  .price-cards-container {
    flex-flow: row wrap;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(md) {
      display: block;
      margin: 32px 6px 0;
    }
  }
}
