@import "./_media";

.flex-row-container {
  display: flex;
}
.flex-column-container {
  display: flex;
  flex-direction: column;
}
.flex-column-mobile {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-1 {
  flex: 1;
}
.flex-0 {
  flex-shrink: 0;
}

.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-between,
.justify-space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}

$widthSizes: 50, 80, 90, 100;
@each $widthSize in $widthSizes {
  .width-#{$widthSize} {
    width: ($widthSize * 1%);
  }
}
.height-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
