// utility mixins and classes for use in components
@mixin box-shadow($level) {
  @if $level == 1 {
    box-shadow:
      0 0.125em 0.313em rgba(50, 50, 93, 0.09),
      0 0.063em 0.125em rgba(0, 0, 0, 0.07);
  } @else if $level == 2 {
    box-shadow:
      0 0.25em 0.375em rgba(50, 50, 93, 0.09),
      0 0.063em 0.188em rgba(0, 0, 0, 0.08);
  } @else if $level == 3 {
    box-shadow:
      0 0.063em 0.313em 0 rgba(0, 0, 0, 0.07),
      0 0.438em 1.063em 0 rgba(0, 0, 0, 0.1);
  } @else if $level == 4 {
    box-shadow:
      0 0.938em 2.188em rgba(50, 50, 93, 0.1),
      0 0.313em 0.938em rgba(0, 0, 0, 0.07);
  } @else if $level == 5 {
    box-shadow:
      0 0.938em 2.188em rgba(50, 50, 93, 0.15),
      0 0.313em 0.938em rgba(0, 0, 0, 0.1);
  }
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // safari has overlapping issue when children are block elements
  * {
    display: inline;
  }
}

.box-shadow-1 {
  @include box-shadow(1);
}
.box-shadow-2 {
  @include box-shadow(2);
}
.box-shadow-3 {
  @include box-shadow(3);
}
.box-shadow-4 {
  @include box-shadow(4);
}
.box-shadow-5 {
  @include box-shadow(5);
}
.line-clamp-1 {
  @include line-clamp(1);
}
.line-clamp-2 {
  @include line-clamp(2);
}
.line-clamp-3 {
  @include line-clamp(3);
}
.line-clamp-4 {
  @include line-clamp(4);
}
.line-clamp-5 {
  @include line-clamp(5);
}
