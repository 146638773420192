@import "redcircle-ui/scss/colors";

.clearSearch {
  cursor: pointer;
}

.progressWrapper {
  display: inline-block;
  background-color: $gray-lightest;
  border-radius: 6px;
  width: 100%;
  height: 8px;
  margin-right: 10px;
}

.progress {
  background: $primary-color;
  height: 8px;
  border-radius: 6px;
}

.playPause {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid $primary-color;
}

.playPauseMini {
  img {
    width: 20px;
  }
}

.mediaBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: $gray-transparent;
  border-radius: 4px;
  width: 485px;
}

.mediaBarProgressWrapper {
  background-color: $gray-lightest;
  border-radius: 2px;
  height: 4px;
  flex: 1;
  margin: 0 10px;
}

.mediaBarProgress {
  background: $primary-color;
  height: 4px;
  border-radius: 2px;
}

.mediaBarDuration {
  font-size: 12px;
  margin-right: 5px;
}

.mediaBarClose {
  cursor: pointer;

  img {
    width: 16px;
  }
}

.formHelpText {
  color: #979797;
  margin-left: 5px;
}

.customAudioTableRow {
  cursor: pointer;
  height: 70px;
}
