@import "redcircle-ui/scss/colors";

.my-cross-promotions-table__header {
  height: 36px;
  background-color: $gray-transparent;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 1px;
  font-size: 11px;
  vertical-align: center;
  padding-top: 11px;
  margin-bottom: 16px;
}

.my-cross-promotions-row__status {
  font-size: 11px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 1px;
}

.my-cross-promotions-row__budget-progress-wrapper {
  border-radius: 1.5px;
  margin-top: 6px;
  background-color: $gray-transparent;
  width: 81px;

  .my-cross-promotions-row__budget-progress {
    border-top-left-radius: 1.5px;
    border-bottom-left-radius: 1.5px;
    background-color: $primary-color;
    height: 3px;
  }
}

.my-cross-promotions-row__date {
  font-size: 13px;
}

.my-cross-promotions-row__context-menu {
  margin-left: auto;
}

.my-cross-promotions-row__podcast {
  line-height: 13px;
  letter-spacing: 0.6px;
  display: flex;
  align-items: center;
  font-size: 11px;

  .my-cross-promotions-row__gray-box {
    background-color: #f5f5f5;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    display: inline-block;
  }
}
