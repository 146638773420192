@import "redcircle-ui/scss/colors";

.BlockFeature {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  z-index: 0;

  h3 {
    max-width: 500px;
  }

  .price-lock-small {
    height: 40px;
    width: 40px;
  }

  &--bordered {
    overflow: initial;
    border: 1px solid $primary-color;
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 20px;

    .CTA {
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--blur {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    background-size: 100% 100%;
    background-repeat: no-repeat;

    filter: blur(10px);
  }

  &--white {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    background-size: 100% 100%;
    background-repeat: no-repeat;

    filter: opacity(30%);
  }

  &--center {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translate(0%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--header {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;

    .header--text {
      margin-left: 10px;
    }
  }

  &--overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(159, 182, 200, 0.1) 0%, rgba(159, 182, 200, 0.1) 100%),
      rgba(159, 182, 200, 0.1);
  }

  &--svg {
    &.blue {
      circle {
        fill: #ebf0f1;
      }

      path {
        fill: $blue-medium;
      }
    }
  }
}
