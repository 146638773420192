@import "src/styles/utility";
.analytics-graphContainer {
  position: relative;
  width: 100%;
}

.analytics-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}
