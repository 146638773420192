@import "../../scss/media";
@import "../../scss/utility";

.audioPlayerWrapper {
  position: fixed;
  width: 100%;
  max-width: 999px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  transition: bottom 0.3s ease-in-out;
  max-height: 200px;

  @include media-breakpoint-down(sm) {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0;
  }

  & > div {
    border-radius: 8px;
    min-height: 0px;

    @include media-breakpoint-down(sm) {
      border-radius: 0;
    }
  }
}

.audioPlayer {
  background: white;
  @include box-shadow(4);
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
}

.flex {
  display: flex;
  align-items: center;
  flex: 1;
  @include media-breakpoint-down(sm) {
    align-items: start;
    flex-direction: column;
    & > div:last-child {
      width: 100%;
    }
  }
}

.controlGrid {
  display: grid;
  grid-template-columns: auto 120px;
  flex: 1;
  column-gap: 1rem;
  row-gap: 0.5rem;

  @include media-breakpoint-down(sm) {
    grid-template-columns: auto 80px;
    row-gap: 0;
    margin-top: 0.5rem;
  }
}

.title {
  max-width: 30%;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.image {
  border-radius: 8px;
}
