@import "redcircle-ui/scss/colors";

.ant-table-box-shadow {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
}
.ant-table-title {
  font-family: Gilroy-SemiBold, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #979797;
  padding: 0 !important;
  margin: 0;
}

.standard-table {
  margin: 0px auto;
  .rt-resizer {
    display: none;
  }

  .ReactTable {
    border: none;
    .rt-thead {
      border: none;
      .rt-tr {
        text-align: left;
        border: none;
        border-bottom: 1px solid rgba($black, 0.1);
        margin-bottom: 0px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
        margin-bottom: 8px;

        .rt-th {
          font-weight: bold;
          line-height: 16px;
          border: none;
          padding-bottom: 12px;
        }
      }
      box-shadow: none;
    }
    .rt-noData {
      color: $black;
    }
    .rt-tbody {
      .rt-td,
      .rt-tr,
      .rt-tr-group,
      .rt-th.rt-resizable-header {
        border: none;
      }
      .rt-tr {
        border-bottom: 1px solid rgba($black, 0.1);
        min-height: 64px;
      }
      .rt-td {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 44px;
      }
    }
  }
  &.no-bottom-line {
    .rt-tr-group:last-child .rt-tr {
      border-bottom: none;
    }
  }

  &.add-top-line {
    .rt-tr-group:first-child .rt-tr {
      border-top: 1px solid rgba($black, 0.1);
    }
  }
}

.two-line-table-row {
  max-width: 100%;
  overflow: hidden;

  .title {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &.no-max-width {
      max-width: none;
    }
  }

  .subtitle {
    font-size: 13px;
    line-height: 24px;
  }
}

.campaign-show-table__search-result {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  display: flex;
  align-items: center;

  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .title {
    display: block;
    max-width: 200px;
  }

  * {
    line-height: 1.2em;
    display: flex;
    justify-content: center;
    flex-flow: column;
  }
  .impressions {
    flex-flow: row;
    align-items: center;
    height: 100%;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
