@import "redcircle-ui/scss/colors";

.promotions-show-page__image {
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 0 8px rgba($black, 0.3);
}

.btn.btn-primary.promotions-show-page__cross-promote-button {
  width: 100%;
  display: block;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.promotions-show-page__title {
  margin-top: 0px;
  margin-bottom: 8px;
}

.promotions-show-page__owner-name {
  opacity: 0.6;
  font-size: 15px;
  margin-bottom: 8px;
}

#promotions-show-page__tabs {
  margin-top: 50px;
}

.promotions-show-page__cross-promote-button-column {
  position: relative;
}

.promotions-show-page__cross-promote-button-tipper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  cursor: not-allowed;
}

.promotions-show-page__highlight-box-wrapper {
  margin-bottom: 22px;
}

#promotions-show-page__tabs ul.nav-tabs {
  li {
    margin-bottom: 0px;
    border: none;
    a {
      color: $black;
      border: none;
      font-size: 15px;
      font-weight: bold;
      cursor: pointer;
    }
    a:hover {
      background: inherit;
    }
    a:active {
      outline: none;
    }
  }
  li.active {
    a {
      color: $black;
      border-bottom: 3px solid $primary-color;
      cursor: pointer;
    }
  }
}

#promotions-show-page__tabs {
  .tab-content {
    line-height: 24px;
    font-size: 15px;
  }
}

#promotions-show-page__tabs-pane-episodes,
#promotions-show-page__tabs-pane-about {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

#promotions-show-page__tabs-pane-about {
  padding-top: 20px;
  .expandable-text {
    margin-bottom: 30px;
  }
}

.promotions-show-details-list-row {
  display: flex;
  line-height: 60px;
  padding: 0px 10px;
  height: 60px;
  position: relative;
  cursor: pointer;
  .promotions-show-details-list-row__details {
    text-align: right;
    font-size: 12px;
  }
  .promotions-show-details-list-row__header {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 20px;
  }
  .promotions-show-details-list-row__title {
    font-weight: bold;
  }
}

.promotions-show-details-list-row-wrapper .media-player-wrapper {
  height: 60px;
}

.promotions-show-details-list-row-wrapper {
  border-bottom: 1px solid $gray-lighter;
}

.promotions-show-details-list-row__content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 500ms;
  padding: 0px 10px;
}

.promotions-show-details-list-row__content--open {
  max-height: 500px;
  overflow-y: auto;
}

.promotions-show-details-list-row__info {
  margin-bottom: 20px;
  margin-top: 24px;
  line-height: 16px;
}

.promotions-show-details-list-row__info a,
.promotions-show-details-list-row__info a:hover,
.promotions-show-details-list-row__info a:visited,
.promotions-show-details-list-row__info a:active,
.promotions-show-details-list-row__info a:link {
  text-decoration: none;
  color: inherit;
}

.promotions-show-details-list-row__info-item {
  font-size: 13px;
}

.promotion-show-details-page__similar-shows {
  margin-top: 60px;
}

.deal-create-modal__intro {
  margin-bottom: 56px;
}
.deal-create-modal__spacer {
  min-height: 100px;
}

.promotions-show-details__no-episodes-text {
  width: 100%;
  text-align: center;
  padding: 30px 0px;
}

.directory-invite-modal__email-link {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.directory-invite-modal__button-column {
  .btn {
    width: 100% !important;
  }
  .glyphicon {
    position: relative;
    top: 2px;
  }
}

.directory-invite-modal__email-copy-link.btn.btn-link.seedcast-btn-link {
  font-weight: bold;
  display: inline;
}

.directory-invite-modal__email-copy-link-wrapper {
  min-height: 200px !important;
}
