@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";

.deal-page__message-container {
  background: $gray-transparent;
  border-radius: 8px;
  height: 80vh;
  padding-bottom: 113px;
  position: relative;

  .messages-wrapper {
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    height: 100%;
    padding: 20px 60px;
  }
  .messages {
    flex: 1;
  }
  .system-message {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    .line {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      height: 1px;
      flex: 1;
    }
    .message {
      font-weight: bold;
      font-size: 13px;
    }
  }
}

.deal-page__new-message-form-wrapper {
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .redcircle-divider {
    margin-top: 0px;
  }
}

.deal-page__new-message-form {
  width: 100%;
  height: 100%;
  background: $gray-transparent;
  form {
    padding: 0px 24px;
  }
}

.deal-page__container {
  max-width: 960px;
  padding-top: 24px;
}

.deal-page__message {
  margin: 10px 0;
  border-radius: 5px;
  color: black;
  padding: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.deal-page__sender-name {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.deal-page__time {
  font-size: 11px;
  margin-left: 8px;
}

.deal-page__message.received {
  background: $white;
  margin-right: 28px;
}
.deal-page__message.sent {
  background: rgba($blue-light, 0.3);
  margin-left: 28px;
}

.deal-box {
  background: $gray-lightest;
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 16px;
  &.requested {
    background: $gray-lightest;
  }
  &.accepted {
    background: $primary-color;
    color: white;
    .deal-box__title {
      opacity: 1;
    }
  }
}

.deal-box__add-button {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  .btn.btn-primary.btn-small {
    margin: 0 !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.deal-box__content {
  background: white;
  color: black;
  border-radius: 8px;
  min-height: 100px;
  position: relative;
  padding: 20px 24px;
  .deal-box__content__air-at {
    font-size: 11px;
  }
}

.deal-box__no-request-text {
  text-align: center;
  padding-top: 20px;
}

.deal-box__title {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-weight: bold;
  opacity: 0.5;
  font-size: 11px;
  letter-spacing: 0.6px;
  height: 28px;
  padding-left: 24px;
  line-height: 28px;
}

.deal-box__title .title {
  text-transform: uppercase;
}

.deal-box__content__show-label.redcircle-form-label,
.deal-box__content__audio-label.redcircle-form-label,
.deal-box__content__date-label.redcircle-form-label {
  margin-bottom: 2px;
}

.deal-box__content__audio-label {
  margin-top: 16px;
}

.deal-box__content__date-label.redcircle-form-label {
  margin-top: 6px;
}

.deal-box__value {
  font-size: 13px;
}

.deal-box__content__show-label-row {
  justify-content: space-between;
  align-items: center;
}

.deal-page__accept-button-wrapper {
  width: 100%;
  padding: 0px 24px;
}

.deal-page__accept-button {
  width: 100%;
}

.deal-page__mobile-toggle {
  .btn {
    outline: none !important;
    margin-bottom: 40px;
  }
}
.deal-page__send-button {
  min-width: auto !important;
  width: 100% !important;
}

@include media-breakpoint-down(md) {
  .deal-page__container {
    padding-top: 60px;
  }
  .deal-page__message-container {
    .messages-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
