@import "redcircle-ui/scss/colors";

.product-tour-popover {
  display: flex;
  border: 4px solid $primary-color;
  background-color: white;
  box-sizing: border-box;
  border-radius: 8px;
  width: auto;
  height: auto;
  padding: 16px;
  position: absolute;

  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 50%;
    border: 1px solid $primary-color;
    height: 30px;
  }

  &:before {
    content: "";
    z-index: 1;
    top: 100%;
    left: 50%;
    position: absolute;
    height: 12px;
    width: 12px;
    color: $primary-color;
    border-radius: 50%;
    background-color: $primary-color;
    margin-top: 28px;
    margin-left: -5px;
  }
}
