@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";
@import "redcircle-ui/scss/spacing";
@import "redcircle-ui/scss/utility";
@import "src/styles/utility";

.show-list {
  display: flex;
  flex-flow: row wrap;
}
.show-list-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.album-art-tag {
  z-index: 999999;
  position: absolute;
  bottom: 2px;
  right: 2px;
}

.show-list-image-wrapper {
  height: 100%;
  width: 100%;
  background: $gray-lighter;
  overflow: hidden;
}

.show-page__title-bar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.show-list-header {
  margin: 0;
}

.show-page-spacer {
  flex-grow: 1;
}

.show-page-divider {
  margin-top: 16px !important;
  margin-bottom: 24px !important;
}
.show-page-header-buttons {
  a:not(:last-child) button {
    margin-left: 0;
  }
}

.show-list-title {
  text-align: center;
  margin: 12px auto;
  font-weight: 500;
  color: $black;
  size: 10px;
  max-width: 20ch;
  font-size: 13px;
  @include line-clamp(2);
}

.show-page__additional-fields {
  margin-top: 20px;
  a {
    text-decoration: none !important;
  }
  div {
    font-size: 14px;
    line-height: 22px;
  }
}

.show-list-image-link-wrapper:hover {
  text-decoration: none;
}

.show-list-table .ReactTable .rt-tbody .rt-tr {
  height: 94px;
}
.show-list-table .ReactTable .rt-tbody .rt-td {
  position: relative;
  border-right: none;
  border-left: none;
}

.show-details-page-image {
  width: 100%;
}

.show-details-page-image-container {
  margin-bottom: 60px;
}

.show-details-main-info__title {
  float: left;
  margin-right: 20px;
  margin-bottom: 0px;
}

.show-details-page-container {
  .demo-import-button {
    max-width: 240px;
  }
}

.show-details-page__episode-list-header {
  margin-top: 60px;
}

.show-details-page__episode-list-header h2 {
  margin: 0px;
  padding: 0px;
}

.show-details-page__episode-list-title {
  margin: 0px;
}

.show-details-page__new-button .btn-small {
  margin-bottom: 0px;
}

.show-page-content-wrapper {
  min-width: 0;
}

.show-details-episode-list {
  min-height: 200px;
  position: relative;

  .ReactTable .rt-thead.-filters {
    border-bottom: none;
  }
  .rt-tbody {
    max-height: 350px;
  }

  .rt-thead.-header {
    box-shadow: none;
  }
  .ReactTable {
    border: none;
  }
  .ReactTable .rt-thead .rt-th {
    padding: 0;
  }
  .rt-td {
    overflow-wrap: normal;
    overflow-x: visible;
    overflow-y: visible;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.show-details-progress-bar {
  margin-bottom: 40px;
}

.seedcast-back-link {
  margin: 40px 0px;
}

.back-link-chevron {
  margin-right: 8px;
}

.seedcast-main-info__title {
  margin-top: 0px;
}

/* medium */
@include media-breakpoint-down(md) {
  .show-list-image-container-wrapper {
    max-width: 100%;
    max-height: 80px;
    position: static;
    width: auto;
    height: auto;
    left: auto;
    margin-left: auto;
    display: inline-block;
  }
  .show-list__show-title {
    line-height: 26px;
  }

  .show-details-page-container {
    margin-left: 10px;
    margin-right: 10px;
  }
  .show-details__title_bar {
    margin-top: 40px;
  }
  .show-page-content-wrapper {
    min-width: 300px;
  }
}

.show-redirect-help-modal {
  font-size: 16px;
  b {
    font-weight: 500;
  }
  ol {
    list-style-position: inside;
    padding: 0;
  }
  li {
    margin-top: 12px;
  }
  li:first-child {
    margin-top: 0px !important;
  }
  .help-content-wrapper,
  .show-redirect-help-modal__select-wrapper {
    margin-top: 40px;
  }
  .help-content__support-section {
    margin: 20px 0px;
  }
}

.show-redirect-help-modal__footer {
  text-align: center;

  .show-redirect-help-modal__close {
    float: right;
  }

  .show-redirect-help-modal__redirect {
    float: left;
  }

  button {
    margin-top: 20px;
    width: 360px;
  }
}

.show-markers-page__album-art {
  height: 240px;
  width: 240px;
  margin-top: 72px;
}

.show-campaign-intro-modal {
  height: 48vh;
}

.campaign-total-payment {
  font-size: 20px;
  line-height: 20px;
  text-align: right;

  display: flex;
  align-items: center;

  &--svg {
    fill: $blue-medium;
    height: 14px;
    width: 14px;
    margin: 0px 5px 2.5px 5px;
  }

  &--tooltip {
    @include set-font("Helvetica Neue", null, 12px, 16px, normal, 0.6px);
    text-transform: none;
    color: $white;
    padding: 5px 8px !important;
    text-align: left;
  }
}

.fake-text-box {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  padding: 16px;
}

.crossed-out-cpm {
  margin: 0px 0px 0px 3px;
  text-align: right;
  text-decoration: line-through;
}
