@import "../../scss/colors";

.base {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: inline;
  }
}

.secondary-red {
  border-width: 2px;
  border-color: $primary-color !important;
  color: $primary-color !important;
  &:hover {
    color: $primary-color-light !important;
    border-color: $primary-color-light !important;
  }

  &:disabled {
    color: $gray-light !important;
    border-color: $gray-light !important;
    border-width: 1px;
  }
}

.outline {
  background: transparent !important;
  border-color: $charcoal !important;
  color: $charcoal !important;
  &:hover {
    background: $charcoal !important;
    color: $white !important;
    border: 1px solid $charcoal !important;
  }
}

.outline-light {
  background-color: transparent !important;
  border-color: $white !important;
  color: $white !important;
  &:hover {
    background: $white !important;
    color: $charcoal !important;
    border: 1px solid $white !important;
  }
}

.outline-red {
  background-color: transparent !important;
  border-color: $white !important;
  color: $white !important;
  &:hover {
    color: $primary-color !important;
    background: $white !important;
    border: 1px solid $white !important;
  }
}

.icon {
  padding: 0 !important;
  svg,
  span {
    width: inherit;
    height: inherit;
  }
}

.flex {
  white-space: normal !important;
  height: auto !important;
}
