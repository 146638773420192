@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/spacing";

.sponsor-show-page {
  .logged-out-frame__logo {
    position: absolute;
    top: 24px;
    left: 40px;
  }
  @media screen and (max-width: 500px) {
    padding-top: 90px;
  }
  .episode-list {
    margin-top: 60px;
    .episode-list-item {
      .episode-title-text {
        margin-right: 10px;
      }
      .cannot-access {
        color: rgba(black, 0.2);
      }
      &:first-child {
        border-top: 1px solid lightgray;
      }

      .episode-list-enter {
        max-height: 0;
      }
      .episode-list-enter-active {
        overflow: hidden;
        max-height: 500px;
        transition: all 300ms ease-in-out;
      }
      .episode-list-exit {
        max-height: 500px;
      }
      .episode-list-exit-active {
        overflow: hidden;
        max-height: 0;
        transition: all 300ms ease-in-out;
      }
      .episode-list-content {
        overflow: hidden;
      }

      cursor: pointer;

      transition: height 0.5s;
      border-bottom: 1px solid lightgray;
      padding: 16px 0;
    }
  }
  .left-column {
    .link-row {
      padding: 29px;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      a {
        text-align: center;
      }
    }
    .sponsor-show-info {
      margin-bottom: 24px;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      h5 {
        font-size: 15px;
      }
      h6 {
        font-size: 13px;
      }
      p {
        font-size: 13px;
      }
      .header {
        text-align: center;
        background: $gray-lightest;
        font-size: 11px;
        @extend .p-ys;
      }
      .content-section {
        padding: 20px;
      }
      .divider {
        height: 1px;
        background: $gray-lightest;
      }
      .highlight-box {
        border-radius: 8px;
      }
      .highlight-box:last-child {
        margin-top: 30px;
      }
    }
    .bottom-links {
      padding: 6px 6px 6px 20px;
    }
  }
}
