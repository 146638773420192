@import "redcircle-ui/scss/colors";

.form-dropdown {
  &-enter {
    max-height: 0;
    display: block;
  }
  &-enter-active {
    overflow: hidden;
    max-height: 500px;
    transition: all 300ms ease-in-out;
  }
  &-enter-done {
    max-height: initial;
  }
  &-exit {
    max-height: 500px;
  }
  &-exit-active {
    overflow: hidden;
    max-height: 0;
    transition: all 300ms ease-in-out;
  }
  &-exit-done {
    display: none;
  }

  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    font-weight: bold;
  }

  &-wrapper__chevron {
    position: relative;
    top: -3px;
    padding: 0px 5px 0px 5px;
  }

  // BADGE
  &-badge {
    $badgeHeight: 24px;
    $badgeColor: #417505;
    $badgeBGColor: rgba(65, 117, 5, 0.1);

    height: $badgeHeight;
    padding: 4px 16px;
    margin: 0px 20px 0px 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    background-color: rgba(65, 117, 5, 0.1);
    border-radius: 4px;

    &-icon {
      height: calc(#{$badgeHeight} / 2);
      width: calc(#{$badgeHeight} / 2);
      fill: $badgeColor;
    }

    &-text {
      color: $badgeColor;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 16px;
      font-weight: normal;
    }

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.distribution-form-instructions {
  margin-bottom: 20px;
}
