@import "src/styles/utility";

.showRemovePage {
  &__disable {
    .showRemovePage__img,
    .form-dropdown-wrapper {
      opacity: 0.4;
    }
  }

  &__dropdown {
    // Manually setting select arrow
    appearance: none;
    background: url(../../../icons/down-chevron.svg) 97% center no-repeat !important;

    // setting styles for select to match other inputs
    &:required:invalid {
      color: gray;
    }
    &:required:valid {
      background-color: rgb(232, 240, 254) !important;
      background-image: none !important;
      color: black !important;
    }
    & > option[value=""][disabled] {
      display: none;
    }
  }

  //Antd cehcbox  overrides
  &__checkbox {
    .ant-checkbox-input {
      margin-top: 0px;
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }
  }

  &__text {
    color: #979797;
  }
}
