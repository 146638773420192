@import "redcircle-ui/scss/colors";

.seedcast-btn-link.btn.btn-link.promotions-warning-bar__button {
  font-weight: bold;
}

.promotions-page__unread-message-count {
  display: inline-block;
  width: 28px;
  height: 20px;
  background: $blue-medium;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.promotions-page__placeholder-image {
  width: 40px;
  height: 40px;
  color: black;
  display: inline-block;
  background: $gray-lighter;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
  margin-right: 10px;
}

.promotions-dashboard__inbox-title {
  margin-bottom: 16px;
}

.promotions-page__deal-request-row {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .spacer {
    flex: 1;
  }
  .state {
    border: 1px solid;
    border-radius: 3px;
    padding: 1px 5px;
    margin-bottom: 0px;
    min-width: 100px;
    text-align: center;
    &.accepted {
      color: blue-medium;
      border-color: blue-medium;
    }
    &.running {
      color: $blue-dark;
      border-color: $blue-dark;
    }
    &.completed {
      color: $burgundy;
      border-color: $burgundy;
    }
  }
}

.promotions-dashboard__browse {
  margin-top: auto;
  position: relative;
  top: 2px;
  margin-bottom: 0px;
}

.promotions-page__browse-description {
  margin-top: auto;
  position: relative;
  top: -1px;
}

.promotions-dashboard__browse-all {
  line-height: 32px;
  text-align: right;
}

.promotions-dashboard__my-promos {
  min-height: 250px;
}

.promotions-dashboard__question-button-wrapper {
  display: flex;
  align-items: flex-end;
  margin-left: 12px;

  .promotions-dashboard__question-button {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 16px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: $gray-transparent;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
}

.promotions-dashboard__new-promotion {
  align-items: flex-end;
  margin-bottom: 16px;
  margin-left: auto;
  display: flex;
}

.promotions-dashboard__my-promos-rows .promotions-dashboard__my-promos-row:first-child {
  padding-top: 4px;
}

.promotions-page__browse-description__view-ignored {
  text-align: center;
}

.promotions-page__empty-state-container {
  text-align: center;
  padding-top: 55px;
  padding-bottom: 55px;

  .btn.btn-primary,
  .btn.btn-secondary {
    max-width: 300px;
    width: 100%;
    margin: 44px auto 0px auto;
  }
}

.promotion-page__deal-label {
  font-size: 11px;
}

.redcircle-divider.promotions-dashboard__my-promos-header-divider {
  margin-top: 12px;
  margin-bottom: 16px;
}
