@import "redcircle-ui/scss/colors";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray-transparent;
  background: $gray-transparent;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  padding: 0;
  color: inherit;
  min-width: auto;
}

.no-circle {
  background: transparent !important;
  box-shadow: none !important;
  border: none;
}
