@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";
@import "redcircle-ui/scss/utility";
@import "src/styles/utility";

.modal.seedcast-modal {
  padding-top: 100px;
  background: rgba(25, 25, 25, 0.3);
  overflow-y: auto;
  z-index: 9999999999;
}

.seedcast-modal {
  width: 90vw !important;
  margin: auto;
  .seedcast-modal__scroll-content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  &.scroll-body-y-override {
    .seedcast-modal__scroll-content {
      overflow-y: hidden;
      .modal-body {
        height: 100%;
      }
    }
  }

  &.large {
    .modal-content {
      max-width: none;
    }
    .seedcast-modal__header {
      max-width: none;
    }

    .seedcast-modal__footer-inner {
      max-width: none;
    }
  }
  &.fill-screen {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    .modal-content {
      height: 100%;
      flex-flow: column nowrap;
      display: flex;
      .seedcast-modal__content {
        flex-flow: column nowrap;
        display: flex;
        flex: 1;
        overflow-y: hidden;
        min-height: initial;
        .container-fluid {
          margin: 0;
        }
        .modal-body {
          flex: 1;
        }
      }
    }
  }
}

.modal-content {
  display: flex;
  flex-flow: column nowrap;
  overflow: visible;
  max-width: 1200px;
  margin: 0px auto;
  width: 100%;
  &.fill-screen {
    height: 100%;
  }
}

.modal-hide-overflow {
  .modal-content {
    overflow: hidden;
  }
}

.modal-button {
  width: 100%;
  display: block;
  margin-top: 24px;
}

.modal-button.btn-secondary {
  margin-top: 24px;
}

.seedcast-modal__header {
  max-width: 960px;
  margin-top: 40px;

  h2 {
    margin-top: 0px;
  }
}

.seedcast-modal__content {
  max-width: 960px;
  margin: 0px auto;
  width: 100%;
  padding: 15px 15px 0 15px;
  min-height: 540px;

  @include media-breakpoint-up(xl) {
    max-width: 1050px;
  }

  &.large {
    max-width: none;
  }
}

.seedcast-modal__content .modal-body {
  padding: 0;
}

.seedcast-modal__footer-inner {
  max-width: 960px;
  margin: 0px auto;
  width: 100%;
  height: 100%;
}

.btn-link.seedcast-modal__close-button {
  position: absolute;
  z-index: 999999;
  color: $black;
  right: 34px;
  top: 20px;
  width: 18px;
  min-width: 0;
  opacity: 0.6;
}

.btn-link.seedcast-modal__close-button:hover {
  color: $black;
}

.seedcast-modal__footer {
  box-shadow: #00000014 0 -3px 7px 0;
  height: 96px;
  @include media-breakpoint-down(md) {
    height: auto;
    padding: 10px 0px;
  }
}

.seedcast-modal__footer--no-shadow {
  box-shadow: none;
}

// Mini Modal Styles
.seedcast-modal--mini {
  .modal-content {
    max-width: 700px;
  }
  .seedcast-modal__footer:not(.show-footer) {
    display: none;
  }
  .seedcast-modal__header {
    margin: 45px 0 8px 0px;

    text-align: left;

    h2 {
      margin: 0px;
    }
  }

  .seedcast-modal__content {
    min-height: unset;
  }

  .mini-modal-footer {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    .btn {
      width: 100%;
    }
  }
}

.post-action-modal {
  .modal-content {
    width: 260px;
    height: 200px;
    text-align: center;
    font-size: 50px;
    font-family: "Courier New", Courier, monospace;
    line-height: 80px;
    top: 20%;
  }
  .post-action-modal__check-mark {
    border: 5px solid black;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin: 20px auto;
    background-image: url("../../icons/check.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  .post-action-modal__message {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }
}

.confirmation-modal {
  &__body {
    padding: 40px 60px 20px 60px;
  }
  &__message {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 60px;
  }
  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .btn {
    margin-bottom: 0px;
  }
  .btn-secondary {
    position: relative;
    top: -2px;
  }
}

@include media-breakpoint-down(md) {
  .confirmation-modal {
    &__body {
      padding: 20px;
    }
  }
}

// View Role Permissions Modal

// Overried Antd Modal styles
.RC-Antd-Override-Modal,
.viewRolePermissionsModal,
.addTeamSeatToPodcastModal,
.insertion-points-modal-overide {
  //Overrides
  .ant-modal {
    &-header {
      padding-top: 40px;
      padding-left: 64px;
      padding-right: 64px;
      border-bottom: initial;
      border: none;
    }

    &-body {
      padding-top: 0px;
      padding-left: 64px;
      padding-right: 64px;
      max-height: 50vh;
      overflow: auto;
      border: none;
    }

    &-footer {
      border: none;
      padding: 24px 64px;
    }
  }

  // Modal Styling
  .title {
    &--1 {
      @include set-font(
        Bold,
        $font-size: 11px,
        $line-height: 16px,
        $font-style: normal,
        $letter-spacing: 1px
      );
      color: #979797;
    }

    &--2 {
      @include set-font(Bold, $font-size: 28px, $line-height: 32px, $font-style: normal);
    }

    &--3 {
      @include set-font(Bold, $font-size: 13px, $line-height: 24px, $font-style: normal);
    }

    &--4 {
      @include set-font(Regular, $font-size: 18px, $line-height: 24px, $font-style: normal);
    }
    &--5 {
      @include set-font(
        Regular,
        $font-size: 11px,
        $line-height: 16px,
        $font-style: normal,
        $letter-spacing: 0.6px
      );
      color: #979797;
    }
    &--6 {
      @include set-font(
        Regular,
        $font-size: 13px,
        $line-height: 24px,
        $font-style: normal,
        $letter-spacing: 0.6px
      );
    }

    &--7 {
      @include set-font(
        Regular,
        $font-size: 12px,
        $line-height: 16px,
        $font-style: normal,
        $letter-spacing: 0.6px
      );
      color: #979797;
    }

    &--8 {
      @include set-font(Regular, $font-size: 15px, $line-height: 24px, $font-style: normal);
    }
  }

  .expandable {
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.4s ease-in-out;
  }

  .expandable.expanded {
    border-top: 1px solid rgb(198, 198, 198);
    max-height: 200px;
    overflow: auto;
  }
}

.addTeamSeatToPodcastModal {
  //Overrides
  .ant-modal {
    &-body {
      padding-top: 0px;
      padding-left: 64px;
      padding-right: 64px;
      padding-bottom: 0px;
      max-height: 55vh;
      overflow: auto;
      border: none;
    }
  }

  .title {
    &--5 {
      color: black;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    width: 60%;
    &-fullname {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      & span:nth-child(1) {
        &::first-letter {
          text-transform: capitalize;
        }
        padding-right: 1.5%;
      }

      & span {
        flex: 0 0 auto;
      }
    }

    &-email {
      width: 100%;
      flex: 1 1 auto;
      max-width: min-content;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.RC-Antd-Override-Modal {
  // Fixing justify space between error
  label.ant-checkbox-wrapper::after {
    display: none;
  }

  label.redcircle-form-label {
    color: #979797;
    @include set-font(
      Bold,
      $font-size: 11px,
      $line-height: 16px,
      $font-style: normal,
      $letter-spacing: 1px
    );
  }

  div.has-feedback {
    margin-bottom: 12px;
    span.help-block {
      margin-bottom: 0px;
    }
  }
}

.RC-Antd-Override-Modal.footer-shadow,
.insertion-points-modal-overide.footer-shadow {
  .ant-modal {
    &-footer {
      font-size: 28px;
      @include box-shadow(3);
    }
  }
}

.insertion-points-modal-overide {
  .ant-modal-content {
    max-height: calc(100vh - 40px);
    height: calc(100vh - 40px);
    min-height: calc(100vh - 40px);

    display: flex;
    flex-direction: column;
  }

  .ant-modal {
    &-header {
      flex: 0 0 auto;
    }

    &-body {
      flex: 1 1 auto;
      max-height: unset;

      overflow-y: auto;
    }

    &-footer {
      flex: 0 0 auto;
    }
  }
}

ol {
  li::marker {
    font-variant-numeric: normal;
  }
}

.check-box-separator {
  & > span:nth-child(2) {
    flex-grow: 1;
  }
}
