@import "./redcircle";

.form-control {
  height: 48px;
  box-shadow: none !important;
}

.form-control-feedback {
  line-height: 48px;
}

.form-group {
  margin-bottom: 20px;
}

/* Buttons */
.btn {
  padding: 12px;
  border-radius: 4px;
  @extend .semi-bold;
  background-image: none;
  box-shadow: none;
  border: none;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  min-width: 100px;
}

.btn.btn-primary {
  background: #ea404d;
  text-shadow: none;
}

.btn.btn-secondary {
  color: $primary-color;
  border: 2px solid $primary-color;
  border-radius: 4px;
  background: $white;
  padding: 10px;
}

.user-form__fine-print {
  font-size: 13px;
  line-height: 24px;
  text-align: center;
}

.form-control-feedback.glyphicon.glyphicon-remove {
  display: none;
}

.form-control.form-control--small {
  height: 24px;
  line-height: 20px;
  padding: 3px 10px 2px 10px;
  font-size: 13px;
}
