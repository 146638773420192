.listen-on-modal {
  .content {
    margin: 0 10px;
  }
  .listen-link {
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: inherit;
    }
  }
  .listen-on-button {
    width: 132px;
    height: 132px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    .icon {
      width: 48px;
      height: 48px;
      border-radius: 5px;
      margin-top: 20px;
    }
    .title {
      margin-top: 25px;
    }
  }
  .rss {
    text-align: center;
  }
}

.listen-on-modal__done-button {
  margin-top: 74px;
  width: 100% !important;
}
