@import "redcircle-ui/scss/colors";

.blue-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
  cursor: pointer;
  circle {
    stroke: $blue-medium;
    stroke-width: 10px;
    fill: none;
  }
}

.filled {
  circle {
    fill: $blue-medium;
  }
}

.episodes-list-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 500px;
}

.episodes-list {
  display: flex;
  align-items: center;
  color: black;
  border-bottom: 1px solid $gray-lightest;
  padding: 10px 0;
}
