@import "redcircle-ui/scss/media";

.teamMemberSignUp {
  margin: 0px auto;
  max-width: 800px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, min-content);
  row-gap: 10px;
  column-gap: 10px;

  @include media-breakpoint-up(md) {
    grid-template-rows: repeat(4, min-content);
  }

  div[class*="form-group"]:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;

    @include media-breakpoint-up(md) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  div[class*="form-group"]:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 3;

    @include media-breakpoint-up(md) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  @for $i from 3 to 5 {
    div[class*="form-group"]:nth-child(#{$i}) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  button[type="submit"] {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  div.has-feedback {
    min-height: 101px;
    margin-bottom: 5px;
    span.help-block {
      margin-bottom: 0px;
    }
  }
}
