@import "src/styles/utility";
@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";

.teamSeatsPage {
  &-text {
    &--1 {
      @include set-font(Bold, $font-size: 32px, $line-height: 36px, $font-style: normal);
    }

    &--2 {
      @include set-font(Regular, $font-size: 18px, $line-height: 24px, $font-style: normal);
    }

    &--3 {
      @include set-font(Bold, $font-size: 16px, $line-height: 24px, $font-style: normal);
    }

    &--4 {
      @include set-font(Regular, $font-size: 11px, $line-height: 16px, $font-style: normal);
    }

    &--5 {
      @include set-font(Regular, $font-size: 13px, $line-height: 16px, $font-style: normal);
    }
  }
  &-panel {
    $maxPanelHeight: 630px;

    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: minmax(300px, 2fr) minmax(300px, 4fr);
    grid-template-rows: minmax(600px, min-content);
    column-gap: 20px;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      grid-template-columns: minmax(300px, 1fr);
      grid-template-rows: repeat(2, min-content);
      row-gap: 10px;
    }

    .TeamMembers,
    .AccessInfo {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
    }

    .TeamMembers {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-height: $maxPanelHeight;

      @include media-breakpoint-up(md) {
        min-height: 600px;
      }
      &-list {
        overflow-y: auto;
      }
      &-footer {
        margin-top: auto;
        box-shadow:
          0px 0px 8px rgba(0, 0, 0, 0.04),
          0px 0px 24px rgba(0, 0, 0, 0.08);
        border-radius: 0px 0px 8px 8px;
      }
    }

    .AccessInfo {
      display: flex;
      flex-direction: column;
      align-content: stretch;
      height: 100%;
      max-width: 100%;
      width: 100%;

      &-border {
        border: 1px solid $gray-lightest;
        box-sizing: border-box;
        border-radius: 4px;
      }

      &-showImg {
        border-radius: 4px;
        height: 24px;
        width: 24px;
      }
      &-header {
        max-width: 100%;
        width: 100%;

        $trashCanWidth: 30px;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        &-info {
          display: flex;
          flex-direction: column;
          max-width: calc(100% - #{$trashCanWidth});

          flex: 1 1 50%;
          &--fullname {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            align-items: center;
            justify-content: flex-start;

            & h1:nth-child(1) {
              &::first-letter {
                text-transform: capitalize;
              }
              padding-right: 5px;
            }

            & h1:nth-child(1),
            & h1:nth-child(2) {
              flex: 1 1 33%;
              max-width: min-content;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          &--email {
            width: 100%;
            max-width: min-content;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        &-trashcan {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          flex: 1 0 $trashCanWidth;
        }
      }

      &-form {
        overflow-y: auto;
      }

      .ant-checkbox-height-fix {
        span.ant-checkbox {
          top: 0px !important;
        }
      }
    }
  }

  .teamMember {
    justify-content: center;
    align-content: center;
    border-radius: 3px;

    &-left {
      flex: 0 0 20%;
      max-width: 54px;

      color: black;
      @include set-font(Bold, $font-size: 20px, $line-height: 28px, $font-style: normal);

      &--logo {
        width: 100%;
        padding-top: 100%;
        position: relative;

        background-color: #ebf0f1;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &--text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0px;
          bottom: 0%;
          height: 24px;
        }
      }
    }

    &-right {
      flex: 0 0 80%;
      max-width: 80%;
      width: 80%;

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: flex-start;
      padding-left: calc(5%);

      &--fullname,
      &--invitebtn {
        @include set-font(Bold, $font-size: 15px, $line-height: 24px, $font-style: normal);
      }

      &--invitetext,
      &--invitebtntext,
      &--email {
        @include set-font(
          Regular,
          $font-size: 11px,
          $line-height: 16px,
          $font-style: normal,
          $letter-spacing: 0.6px
        );
      }

      &--email {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &--fullname {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;

        & span:nth-child(1) {
          &::first-letter {
            text-transform: capitalize;
          }
          padding-right: 3px;
        }

        & span:nth-child(1),
        & span:nth-child(2) {
          flex: 0 1 auto;
        }
      }

      &--invitetext {
        flex: 1 1 auto;
        overflow: hidden;
        white-space: nowrap;
        opacity: 0.4;
        margin: 0px;
        padding-left: 3px;
      }
    }

    &.selected {
      background-color: $primary-color;

      .teamMember {
        &-right {
          &--fullname,
          &--invitetext,
          &--email,
          &--invitebtntext,
          &--invitebtn {
            color: white;
          }

          &--invitetext {
            opacity: initial;
          }
        }
      }
    }

    &:not(.selected) .pending {
      opacity: 0.4;
    }
  }
}
