@import "redcircle-ui/scss/utility";
@import "redcircle-ui/scss/media";

.message-bar {
  position: fixed;
  top: 12px;

  z-index: 1000000;
  opacity: 0;
  visibility: hidden;
  @include box-shadow(2);
  padding: 1.5rem 2rem;

  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    width: 50%;
    transform: translateX(50%);
  }
  @include media-breakpoint-down(md) {
    left: 24px;
    right: 24px;
  }
}

.visible {
  animation: fadeIn 0.3s linear;
  opacity: 1;
  visibility: visible;
}

.invisible {
  animation: fadeOut 0.3s linear;
  opacity: 0;
  visibility: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
