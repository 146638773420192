@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";
@import "src/styles/utility";

.analyticsPage {
  margin: 75px 5px 0;

  @include media-breakpoint-up(md) {
    margin: 50px 10px 0 !important;
  }

  &-dropdown {
    border-radius: 4px;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    @include set-font("Bold", $font-size: 15px, $line-height: 1.33em, $letter-spacing: 0.04em);
    margin: 0px 0px 16px 0px;
    text-align: center;

    @include media-breakpoint-up(md) {
      @include set-font("Bold", $font-size: 20px, $line-height: 1.4em);
      margin: 0px 0px 32px 0px;
    }
  }

  &-text {
    @include set-font("Bold", $font-size: 13px, $line-height: 1.84em);

    @include media-breakpoint-up(md) {
      @include set-font("Bold", $font-size: 15px, $line-height: 1.33em, $letter-spacing: 0.04em);
    }
  }

  &-responsiveRow {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 8px 0px;

    &.reverse {
      flex-direction: column-reverse;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      &.reverse {
        flex-direction: row-reverse;
      }
    }
  }

  &-GraphTooltip {
    padding: 8px 16px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &--title {
      @include set-font("Bold", $font-size: 11px, $line-height: 1.4545em, $letter-spacing: 0.09em);
      text-transform: uppercase;
      text-align: start;
      padding-bottom: 8px;
      color: #000000;
    }

    &--item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      span:first-child {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin: 0px 10px 0px 2px;
      }

      span.text {
        @include set-font("Bold", $font-size: 13px, $line-height: 1.8463em);
        margin-right: 5px;
        color: #000000;
      }

      span:last-child {
        margin-left: auto;
        @include set-font("Regular", $font-size: 18px, $line-height: 1.333em);
        color: #000000;
      }
    }

    &--text {
      @include set-font(
        "Regular",
        $font-size: 11px,
        $line-height: 1.4545em,
        $letter-spacing: 0.09em
      );
      text-transform: capitalize;
      text-align: start;
      padding-bottom: 4px;
    }
  }

  // Graph

  &-graphContainer {
    &--Donut {
      margin: 0px auto 10px auto;
    }

    &--TimeMap {
      min-height: 650px;
      width: 100%;
    }
  }

  //Row

  &-Row {
    &--2 {
      margin-left: -10px;
      margin-right: -10px;
      display: grid;
      grid-template-columns: repeat(1, 100%);
      grid-template-rows: repeat(2, min-content);
      column-gap: 32px;
      row-gap: 24px;
      justify-content: center;

      @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(2, calc(50% - 16px));
        grid-template-rows: repeat(1, 1fr);
      }
    }

    &--3 {
      margin-left: -10px;
      margin-right: -10px;
      display: grid;
      grid-template-columns: repeat(1, 100%);
      grid-template-rows: repeat(3, min-content);
      column-gap: 20px;
      row-gap: 24px;
      justify-content: center;

      @media screen and (min-width: 1300px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: repeat(1, min-content);
      }
    }
  }

  // Table style overrides

  &-table {
    $new-pimary-color: #1890ff;

    background: #fafafa;

    & li.ant-pagination-prev,
    & li.ant-pagination-next {
      &:hover,
      &:focus {
        & > button.ant-pagination-item-link {
          color: #1890ff;
          border-color: #1890ff;
        }
      }
    }

    & li.ant-pagination-item {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 2px;

      &:hover,
      &:focus {
        & > a {
          color: $new-pimary-color;
        }
      }

      & > a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include set-font("Regular", $font-size: 14px, $line-height: 16px);
        color: rgba(0, 0, 0, 0.65);
      }

      &-active {
        border-color: $new-pimary-color;

        & > a {
          color: $new-pimary-color;
        }
      }
    }

    &-colorCell {
      padding: 4px !important;
      min-width: 15px;
      background: #fafafa;
    }

    th.analyticsPage-table-colorCell {
      background: #f2f2f2;
    }

    th.analyticsPage-table-cell {
      color: #979797;
      background: #f2f2f2 !important;
      padding: 8px;
      text-transform: uppercase;
      @include set-font("Bold", $font-size: 13px, $line-height: 1.846em, $letter-spacing: 0.046em);

      & .ant-table-column-sorters {
        padding: 8px;
      }
    }

    td.analyticsPage-table-cell {
      color: #000000;
      background: #fafafa;
      padding: 8px;
      @include set-font(
        "Regular",
        $font-size: 13px,
        $line-height: 1.846em,
        $letter-spacing: 0.046em
      );
      & > .episodeContainer {
        .showName {
          color: #464646;
          @include set-font(
            "Regular",
            $font-size: 11px,
            $line-height: 1.45em,
            $letter-spacing: 0.054em
          );
        }
      }
    }
  }

  //Sections

  &-DownloadsOverview {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(4, 1fr);
    gap: 24px;

    @media screen and (min-width: 500px) {
      grid-template-columns: repeat(2, minmax(min-content, 1fr));
      grid-template-rows: repeat(2, 1fr);
    }
    @media screen and (min-width: 1250px) {
      grid-template-columns: repeat(4, minmax(min-content, 1fr));
      grid-template-rows: repeat(1, 1fr);
    }
  }

  &-GeolocationPerformance {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, min-content);
    row-gap: 10px;
    column-gap: 24px;

    @media screen and (min-width: 1300px) {
      grid-template-columns: 1.2fr 0.8fr 1fr 1fr;
      grid-template-rows: repeat(3, min-content);
    }

    &--title {
      grid-column: 1 / 3;
      grid-row: 1 / 2;

      @media screen and (min-width: 950px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      @media screen and (min-width: 1300px) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
    }

    &--dropdown {
      grid-column: 1/3;
      grid-row: 2/3;
      justify-self: flex-start;
      @media screen and (min-width: 950px) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        justify-self: flex-end;
      }

      @media screen and (min-width: 1300px) {
        grid-column: 3 / 5;
        grid-row: 1 / 2;
      }
    }

    &--mapbox {
      grid-column: 1/3;
      grid-row: 3/4;
      @media screen and (min-width: 950px) {
        grid-row: 2/3;
      }

      @media screen and (min-width: 1300px) {
        grid-column: 3 / 5;
        grid-row: 2 / 3;
      }
    }

    &--episodes {
      grid-column: 1/3;
      grid-row: 4/5;
      justify-self: flex-start;
      @media screen and (min-width: 950px) {
        grid-row: 3/4;
        justify-self: flex-end;
      }

      @media screen and (min-width: 1300px) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
    }

    &--table {
      grid-column: 1/3;
      grid-row: 5/6;
      @media screen and (min-width: 950px) {
        grid-row: 4/5;
      }

      @media screen and (min-width: 1300px) {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }
    }
  }

  &-quickaccess {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(4, 1fr);
    gap: 24px;

    @media screen and (min-width: 500px) {
      grid-template-columns: repeat(2, minmax(min-content, 1fr));
      grid-template-rows: repeat(2, 1fr);
    }
    @media screen and (min-width: 1250px) {
      grid-template-columns: repeat(3, minmax(min-content, 1fr));
      grid-template-rows: repeat(1, 1fr);
    }

    &--container {
      height: 154px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      background: #ffffff;
      border: 1px solid #e5e5e5;
    }

    &--title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &--text {
      margin-left: 8px;
      @include set-font("Regular", $font-size: 18px, $line-height: 1.33em);
    }

    &--btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: $blue-medium;
      border-radius: 2px;
      padding: 6px 16px;
      margin-top: 22px;
      border: 0pc solid transparent;

      @include set-font("Bold", $font-size: 13px, $line-height: 1.23em);
      color: #ffffff;

      &:hover,
      &:active,
      &:focus {
        color: #ffffff;
        text-decoration: inherit;
      }
    }
  }

  &-RevenueOverview,
  &-InsertionsOverview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 24px;

    @media screen and (min-width: 500px) {
      grid-template-columns: repeat(2, minmax(min-content, 1fr));
      grid-template-rows: repeat(2, 1fr);
    }
    @media screen and (min-width: 1250px) {
      grid-template-columns: repeat(5, minmax(min-content, 1fr));
      grid-template-rows: repeat(1, 1fr);

      & > div.analytics:nth-child(1):nth-last-child(6) {
        grid-column-start: 1;
        grid-column-end: -1;
        border: 1px solid $gray-lighter;
        border-top: 4px solid $blue-medium;
      }
    }
  }

  &-top5 {
    display: grid;
    grid-template-columns: repeat(1, minmax(min-content, 1fr));
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;

    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(2, minmax(min-content, 1fr));
      grid-template-rows: repeat(1, 1fr);
    }

    &--card {
      padding: 16px 32px;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    }
  }
}

// TODO - Override Antd components with Antd less variable config
.RC-Antd-Override {
  &-Dropdown {
    border-radius: 4px;
    @include set-font($font-size: 13px!important, $line-height: 1.538em!important);

    &-Option {
      & > div.ant-select-item-option-content {
        text-overflow: initial;
      }
    }
  }

  &-Switch {
    margin: auto 10px !important;
    @include media-breakpoint-up(md) {
      margin: auto 20px !important;
    }

    &.ant-switch-checked {
      background-color: $blue-medium;
    }
  }
}

.RC-Bootstrap-Override {
  &-Tabs {
    ul.nav.nav-tabs {
      li a {
        color: black;
        transition: color 0.3s ease-in-out;
        padding: 5px 8px !important;
        @include set-font("Bold", $font-size: 13px!important, $line-height: 1.6em!important);
        @include media-breakpoint-up(md) {
          @include set-font("Bold", $font-size: 15px!important, $line-height: 1.6em!important);
          padding: 10px 15px !important;
        }
      }
    }

    li.active a {
      color: $blue-medium !important;
    }
  }
}

.area-switch-text.h3 {
  @include media-breakpoint-down(md) {
    @include set-font($font-size: 15px!important, $line-height: 1.6em!important);
  }
  color: $blue-medium;
}
