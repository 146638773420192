@import "redcircle-ui/scss/media";
@import "redcircle-ui/scss/colors";

$table-header-color: $gray-lighter;

.table {
  th {
    background: $table-header-color !important;
  }
  :global(.ant-table-thead .ant-table-cell) {
    text-transform: uppercase;
    font-family: Gilroy-Semibold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.6px;
    color: rgba(black, 0.5);
  }
  :global(.ant-table-cell) {
    padding: 12px 12px !important;
  }
  :global(.ant-table-thead .ant-table-cell.ant-table-column-sort) {
    color: black !important;
  }

  @include media-breakpoint-down(md) {
    :global(.ant-table-content) {
      overflow-x: auto;
    }
  }
}

.bar {
  background: $table-header-color !important;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}
