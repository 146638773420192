@import "redcircle-ui/scss/media";
@import "redcircle-ui/scss/utility";

.page {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  max-width: 100%;

  main {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & > *:not(:last-child) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-width: auto;
    @include media-breakpoint-up(md) {
      max-width: 1072px;
    }
  }

  .browse-shows-grid,
  .browse-shows-table {
    padding-right: 24px;
    @include media-breakpoint-down(md) {
      padding-right: 12px;
    }
  }

  @include media-breakpoint-down(md) {
    height: calc(100vh - 60px);
  }
}

.browse-shows-container {
  overflow: auto;
}

.browse-shows-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  & > div {
    flex-wrap: nowrap;
  }
}

.browse-shows-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;
  padding-left: 5px;
  padding-top: 5px;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.browse-shows-grid,
.browse-shows-table {
  max-width: 1072px !important;
  margin-left: auto;
  margin-right: auto;
}

.show-block {
  color: black;
  position: relative;
}

.add-button {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.show-image {
  aspect-ratio: 1 / 1;
  height: auto;
}

.table-image {
  width: 64px;
  height: 64px;
  margin-right: 12px;
  min-width: 64px;
  min-height: 64px;
}

.recommended-tag {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

// SIDEBAR

.filters {
  // some of these should be global overrides
  :global(.ant-collapse-header) {
    padding: 12px 16px 0 !important;
  }
  :global(.ant-collapse-item:not(:last-child)) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  :global(.ant-collapse-item) {
    padding-top: 12px;
    padding-bottom: 24px;
  }
  :global(.ant-collapse-header-text) {
    text-transform: uppercase;
    font-weight: bold;
  }
  :global(.ant-collapse-arrow) {
    top: 65% !important;
  }
  :global(.ant-collapse-content-box) {
    padding-bottom: 0 !important;
  }
  :global(.ant-input-affix-wrapper-sm .ant-input-clear-icon) {
    margin-top: 5px;
  }
}

.filter-button {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.sort {
  display: flex !important;
  flex-direction: column;
}

.categories {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex !important;
  flex-direction: column;
}

.sidebar {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile-sidebar {
  display: flex;
  @include media-breakpoint-down(md) {
    @include box-shadow(3);
    z-index: 999;
    background: white;
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 75% !important;
    animation-duration: 0.3s;
    animation-name: slideIn;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
