@import "redcircle-ui/scss/media";

.categories {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex !important;
  flex-direction: column;
  padding-right: 0.5rem;
}

.table-wrapper {
  max-height: 500px;
  overflow-y: auto;

  @include media-breakpoint-down(md) {
    max-height: 450px;
  }
}
