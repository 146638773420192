@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/utility";

.button {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 9999999999;
  background: white !important;
  border-radius: 50% !important;
  @include box-shadow(5);
}
