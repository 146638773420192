@import "redcircle-ui/scss/media";

.grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 12px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}
