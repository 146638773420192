@import "redcircle-ui/scss/colors";

$FOLDER_COLOR: #fff2f5; // $primary-color at 0.03 opacity

.linkTitle {
  color: inherit;
  cursor: pointer;
}

.isFolder,
.isFolder td {
}

// DROPPABLE
.droppable {
  transition: background 0.5s;
  td:first-child {
    padding: 0 !important;
    min-width: 300px;
  }
}
.isAnyOver,
.isAnyOver td {
  background: rgba($primary-color, 0.1) !important;
}

// DRAGGABLE
.nameCell:not(.isChild) {
  padding: 12px;
}
.draggable {
  touch-action: none;
  cursor: move;
}
.childIndicator {
  margin-left: 20px;
  margin-right: 32px;
  height: 57px;
  min-width: 3px;
  background: #f2f2f2;
}

// DRAGGING
.isDragging {
  position: relative;
  z-index: 9999;
  border: 1px solid $primary-color;
  border-radius: 5px;
  background: white;
}

.archived {
  color: gray;
}
