h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.3;
}

.preheader {
  color: $gray;
  font-weight: bold;
  font-size: small;
  letter-spacing: 0.7px;

  svg * {
    fill: $gray;
  }
}

.redcircle-form-label {
  font-weight: 500 !important;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 8px;
}

.text-subtle {
  font-size: 15px;
  color: $gray-dark;
}

.text-block-expanded {
  @extend .p-ls;
  border-left: 2px solid $gray-light;
}
