textarea.rc-textarea-input {
  padding-left: 5px;
  overflow-wrap: "break-word";
  resize: vertical;
  height: 24px;
  min-height: 24px;
  line-height: 1.28em;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  max-height: 80px;
  outline: none;
  transition: border 0.2s ease-in-out;

  &:disabled {
    border: none;
    color: gray;
    pointer-events: none;
  }

  &:focus-visible,
  &:hover {
    border: 1px solid #f76a71;
  }
}
