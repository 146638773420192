@import "redcircle-ui/scss/colors";

.media-player__media-controls {
  display: flex;
  align-items: center;
  height: 50px;
}
.media-player__seek-bar {
  flex-grow: 1;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .seekbar-wrapper {
    cursor: pointer;
    border-radius: 1.5px;
    background-color: $gray-transparent;
    display: flex;
    margin-right: 10px;
    flex: 1;
    .seekbar {
      border-top-left-radius: 1.5px;
      border-bottom-left-radius: 1.5px;
      background-color: $primary-color;
      height: 5px;
    }
  }

  .duration-text {
    display: inline-block;
    text-align: end;
  }
}

.media-player__play-pause_wrapper {
  height: 50px;
  width: 30px;
  margin-left: -7px;
  display: flex;
  align-items: center;
  &.mini {
    height: auto;
    button {
      height: auto;
    }
  }
}

.media-player__info-bit-wrapper {
  position: relative;
  left: -10px;
  top: -10px;
}

.media-player__play-pause_wrapper button {
  background: none;
  border: none;
  border-radius: 25px;
  height: 50px;
  text-align: center;
  outline: none;
  padding: 0;
  margin: 0;

  span {
    position: relative;
    left: -9px;
    top: -1px;
  }
}

.media-player__context-menu {
  margin-left: 20px;
}

.media-player__loading-button {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  top: -2px;
  right: -5px;
  border-radius: 50%;
  border: 2px solid $black;
  border-color: $black transparent $black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.media-player__media-controls {
  input[type="range"] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px $black;
    background: $primary-color;
    border-radius: 1px;
    border: 0px solid $black;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px $black;
    border: 1px solid $burgundy;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $gray-lightest;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $primary-color;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px $black;
    background: $primary-color;
    border-radius: 1px;
    border: 0px solid $black;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px $black;
    border: 1px solid $burgundy;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $gray-lightest;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: $primary-color;
    border: 0px solid $black;
    border-radius: 2px;
    box-shadow: 0px 0px 0px $black;
  }
  input[type="range"]::-ms-fill-upper {
    background: $primary-color;
    border: 0px solid $black;
    border-radius: 2px;
    box-shadow: 0px 0px 0px $black;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px $black;
    border: 1px solid $burgundy;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $gray-lightest;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: $primary-color;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: $primary-color;
  }
  &.mini {
    height: auto;
    .media-player__play-pause_wrapper {
      height: auto;
      button {
        height: auto;
      }
    }
  }
}
