@import "src/styles/utility";

.showEmbedPage {
  &__row {
    width: 100%;
    display: grid !important;
    grid-template-columns: minmax(50%, max-content) 1fr minmax(45%, max-content);
    grid-template-rows: 1fr min-content;
    column-gap: 10px;
    padding: 29px 0px 0px 0px !important;
  }

  &__vl {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;

    justify-self: center;
    justify-content: center;
    align-self: center;
    align-content: center;

    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__themes,
  &__bgcolor {
    margin: 0px 0px 12px 0px;
    display: flex;
    align-items: center;
    &--text {
      @include set-font(Gilroy, Bold, 11px, 16px, normal, 1px);
      text-transform: uppercase;
      color: #000000;
    }

    &--switch {
      &.ant-switch {
        margin-left: 24px;
      }

      &.ant-switch-checked {
        background-color: #577d9e;
      }
    }

    &--popover {
      position: relative;
      content: "";
      width: 1px;
      height: 1px;

      & > div {
        position: absolute;
        margin: 0px;
        z-index: 1;
      }
    }
  }

  &__themes {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  &__bgcolor {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 4;
  }

  &__btnGroup {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;

    display: flex;
    // flex-wrap: wrap;
    background-color: #fafafa;
    padding: 4px 8px;
    border-radius: 4px;
    overflow-x: hidden;
    &--btn {
      flex: 1 1 auto;
      @include set-font(Gilroy, Regular, 14px, 14px, normal);
      background-color: white;
      color: #000000;
      padding: 9px 16px;
      border-radius: 4px;
      border: 0px solid transparent;
      position: relative;
      height: auto;

      &:not(:last-child) {
        &::after {
          position: absolute;
          content: "";
          right: -8px;
          width: 1px;
          height: 80%;
          top: 10%;
          background-color: rgba(0, 0, 0, 0.1);
        }
        margin-right: 17px;
      }

      &.btnActive {
        background-color: #577d9e;
        color: #ffffff;
        font-weight: bold;
      }

      &:disabled {
        opacity: 0.4;
      }
    }
  }

  & .colorPicker {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;

    display: flex;
    &__btn {
      height: 100%;
      display: flex;
      padding: 4px 8px;
      margin: 0px;
      background: #fafafa;
      border-radius: 4px;
      border: 0px solid transparent;
      &--color {
        display: inline-block;
        min-height: 32px;
        height: 100%;
        width: 64px;
        border: 1px solid #e5e5e5;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        margin-right: 8px;
        content: "";
      }

      &--text {
        width: 89px;
        min-height: 32px;
        height: 100%;
        text-align: center;
        padding: 9px 16px;
        background: #fafafa;
        border: 1px solid #e5e5e5;
        @include set-font(Gilroy, Regular, 14px, 14px, normal);

        display: flex;
        align-items: center;
      }

      & button {
        height: 100%;
      }

      &.pickerDisabled {
        opacity: 0.4px;
        pointer-events: none;
        & button {
          padding: 0px;
          border: 0px solid transparent;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__title {
    @include set-font(Gilroy, Bold, 11px, 16px, normal, 1px);
    text-transform: uppercase;

    display: flex;
    align-items: center;
    margin: 40px 0px 8px 0px;
    &--icon {
      fill: #e5e5e5;
      height: 13.33px;
      width: 13.33px;
      margin: 0px 0px 4px 8px;
    }

    &--tooltip {
      @include set-font("Helvetica Neue", 400, 12px, 16px, normal, 0.6px);
      max-width: 216px;
      border-radius: 4px;
      padding: 3px 8px;
      text-transform: initial;
    }
  }

  &__player {
    width: 100%;
    height: 424px;
  }

  &__copyBtn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__helpText {
    @include set-font(Gilroy, 400, 15px, 24px, normal);
    color: #000000;

    &--link {
      @include set-font(Gilroy, 400, 15px, 24px, normal, 0.6px);
      color: #ea404d;
    }
  }
}
