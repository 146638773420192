@import "redcircle-ui/scss/colors";

.sponsorship-modal {
  .seedcast-modal__content {
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    min-height: 0;
  }

  .modal-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .title-center,
  .title {
    &-center {
      text-align: center;
    }
  }

  .page-3-container {
    .table-row {
      align-items: center;
      border-bottom: 1px solid rgba(black, 0.1);
      height: 60px;
    }
    .left-column {
      font-size: 18px;
    }
    .brand {
      font-size: 15px;
      font-weight: bold;
    }
    .last4 {
      font-weight: bold;
      text-align: right;
      opacity: 0.6;
      font-size: 11px;
      color: black;
      letter-spacing: 0.6px;
    }
    .amount {
      font-size: 18px;
      line-height: 24px;
    }
    .info {
      text-align: center;
    }
    .footer {
      width: 100%;
      position: absolute;
      top: 635px;
    }
  }
  .page-1-container {
    .album-art {
      height: 200px;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.4);
      border-radius: 5px;
    }

    text-align: center;

    .price-form {
      text-align: left;
      .no-highlight-container {
        padding-left: 16px;
        padding-right: 16px;
        small {
          font-weight: 500;
        }
      }
      .highlight-box {
        text-align: left;
        background: $gray-transparent;
        height: auto;
        margin-bottom: 14px;

        label {
          margin-bottom: 0px;
        }

        .support-text {
          font-family: Gilroy-Regular, sans-serif;
          font-weight: 500;
        }
      }
      .offset-radio[type="radio"]:not(:checked) + label:before,
      .offset-radio[type="radio"]:checked + label:before {
        top: 14px;
      }
      .offset-radio[type="radio"]:checked + label:after {
        top: 18px;
      }

      .footer-button {
        margin-top: 24px;
      }
    }
  }
}
