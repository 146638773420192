$primary-color-disabled: #f49fa6;

.loading-spinner-wrapper {
  position: relative;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-button__spinner {
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  height: 36px;
}

// override disabled styling for loading button
.loading-button {
  font-weight: 500;
  &.ant-btn-primary:disabled,
  &.ant-btn-primary:disabled:hover {
    background: $primary-color-disabled;
    border-color: $primary-color-disabled;
    color: white;
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
