@import "redcircle-ui/scss/colors";

.react-autosuggest__suggestions-container {
  position: absolute;
  background: white;
  border-radius: 4px;
  z-index: 10;
  margin-top: 5px;
  width: 100%;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid $gray-lightest;
}

.react-autosuggest__suggestions-list {
  padding: 12px;
  list-style: none;
}

.react-autosuggest__suggestion {
  line-height: 28px;
  font-size: 13px;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  cursor: pointer;
}

.react-autosuggest__suggestion:hover {
  color: $primary-color;
}

.react-autosuggest__loader {
  width: 50px;
  height: 50px;
  position: relative;
}
