@import "src/styles/utility";

.loading-image-container {
  display: block;
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
  background: rgba(229, 229, 229, 0.3);
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }

  // Conditionally render tag on data property
  &[data-image-tag-text] {
    img {
      opacity: 0.4 !important;
    }

    &::after {
      content: attr(data-image-tag-text);
      position: absolute;
      bottom: 4%;
      right: 3%;
      min-width: 1em;
      max-width: 94%;
      height: auto;
      background: var(--image-tag-bgcolor, #ffe7be);
      border-radius: 4px;
      text-align: center;
      @include set-font(
        $font-family: Gilroy,
        $font-weight: Bold,
        $font-size: 10px,
        $letter-spacing: 1px
      );

      color: var(--image-tag-color, black);
      padding: 0px 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
