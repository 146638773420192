@import "redcircle-ui/scss/colors";

.form-step {
  display: block;
}

.form-step.form-step--invisible {
  display: none;
}

.form-step-dots {
  height: 50px;
  width: 100%;
  padding-top: 50px;
  margin-top: -50px;
  position: relative;

  .form-step-dots__line {
    height: 4px;
    background-color: $gray-lightest;
    width: 100%;
    position: absolute;
    top: 24px;
  }
  .form-step-dots__dot {
    background-color: $gray-lightest;
    height: 20px;
    width: 20px;
    border-radius: 25px;
    position: absolute;
    top: 15px;
    border: 2px solid white;
  }
  .form-step-dots__dot.form-step-dots__dot--selected {
    background-color: $blue-light;
  }
}

.multistep-nav {
  .multistep-nav-item {
    cursor: pointer;
    padding: 8px;
    line-height: 24px;
    border-radius: 4px;
    &.disabled {
      color: lightgray;
      cursor: default;
    }
    &.active {
      background-color: $primary-color;
      color: white;
    }
    &.inactive-dim {
      background-color: inherit;
      opacity: 0.5;
    }
  }
  .parent-label {
    color: #c6c6c6;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 2em;
  }
}
