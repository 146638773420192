@import "redcircle-ui/scss/colors";

.link {
  &:hover {
    text-decoration: none;
  }
}

.nav {
  min-height: 115px;
  background: $gray-transparent;
  padding: 20px 24px;
  border-radius: 8px;
  color: black;
  position: relative;

  & img {
    position: absolute;
    right: 16px;
    top: 20px;
    opacity: 0.2;
  }

  &.disabled p,
  &.disabled h3 {
    color: lightgray;
  }
}

.no-min-height {
  min-height: auto;
}

.disabled {
  color: lightgray;
  fill: lightgray;
}
