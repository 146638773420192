@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/utility";

.card {
  height: auto;
  color: inherit;
  padding: 16px;
  border-radius: 4px;
  transition: height 0.3s;

  @include box-shadow(1);

  &:not(:disabled) {
    border: 2px solid $primary-color;
  }

  &:disabled {
    border: 2px solid $gray-lighter;
  }
}

.readOnly {
  cursor: auto;
  &:hover {
    color: inherit !important;
  }
}
