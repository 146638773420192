@import "redcircle-ui/scss/colors";

.distribution-form-row {
  & .form-group {
    margin-bottom: 0px;
    flex: 1;
    margin-right: 15px;
  }

  & .redcircle-form-label {
    margin: 15px 0px;
  }

  &__image-wrapper {
    margin-top: 9px;
  }
  &__image {
    width: 100%;
    border-radius: 8px;
  }

  & &__input-container {
    display: flex;
    flex-wrap: wrap;
  }

  & input.form-control {
    min-width: 300px;
  }

  & button {
    min-width: 150px;
    height: 48px;
  }

  & .form-dropdown-wrapper {
    margin-top: 16px;
  }

  & .tooltip--right:hover:after {
    font-weight: bold;
    top: -10px;
    text-transform: none;
  }

  &--tabs {
    & a {
      color: black;
      font-weight: bold;

      &:hover {
        color: black;
      }
    }

    &.active a {
      color: rgb(87, 125, 158) !important;
    }
  }

  & .tab-content {
    padding-top: 10px;
  }

  &-instructions {
    padding: 10px;

    &--title {
      font-family: Gilroy, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0px 0px 8px 0px;

      color: #000000;
    }

    &--list {
      font-family: Gilroy, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #000000;
      padding: 0px;

      margin: 0px;

      & > li {
        font-family: Gilroy, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        color: #000000;
        margin: 0px 0px 12px 16px;

        &::marker {
          font-weight: normal;
        }
      }
    }

    &--text {
      font-family: Gilroy, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 12px;
    }

    &--link {
      font-family: Gilroy, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.6px;
      color: #ea404d;
    }

    &--clipboard {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #eeeeee;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Gilroy, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      padding: 0px 10px 0px 10px;
      margin-bottom: 16px;

      & > span:first-child {
        flex: 1 1 auto;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & > span:last-child {
        flex: 0 0 50px;
      }
    }
  }

  &__input-container {
    & .btn.btn-primary[disabled] {
      color: #979797;
      background-color: #fafafa !important;
    }
  }
}

.distributions-modal-intro {
  margin: 15px 0px 40px 0px;
}

.distributions-modal-outtro {
  margin-top: 20px;
}

.spotify-distribution-error {
  padding: 1.5em;
  border-radius: 8px;
  margin-bottom: 10px;
  background: $red-lighter;
}
