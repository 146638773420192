@import "redcircle-ui/scss/media";

.info * {
  fill: #979797;
}

.list {
  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
  margin-bottom: 8px;
  display: grid;
  padding: 5px 0 5px 5px;
  border-radius: 4px;
}

.empty {
  background: #fafafa;
  width: 100%;
  height: 100%;
}

.audio-block-selected {
  border: 1px solid #d9d9d9;
  background: white;
  border-radius: 4px;
  padding: 5px 7px;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    flex: 0;
    padding: 0;
  }
}

.dropdown-highlighted {
  .ant-select-selector {
    background: blue;
  }
}

// BULK ASSIGN

.bulk-assign-toolbar {
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 16px;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      width: 100%;
    }
    & > *:first-child {
      margin-right: 0;
    }
  }
}

.bulk-assign-add-remove {
  background: #eee;
  padding: 4px 12px;
  border-radius: 4px;
  height: 40px;
}

.bulk-assign-episode-cell {
  min-width: 300px;
}

.invalid {
  * {
    fill: #8a6d3b;
  }
  color: #8a6d3b;
}
