@import "redcircle-ui/scss/colors";

$input-width: 105px;
$cell-width: 8px;

.wrapper {
  border: 1px solid $gray-light;
  border-radius: 4px;
  transition: border 0.3s;
}

.wrapperOpen {
  border: 1px solid $primary-color;
}

.input {
  width: $input-width;
  border-radius: 0;
}

.calendar {
  :global(.ant-picker-cell-disabled) {
    &::before {
      background-color: white;
    }
  }
}

// CELL STATES
.today {
  border: 1px solid $gray;
  border-radius: 4px;
}

.hover {
  &:hover {
    background-color: $primary-color;
    color: white;
    border-radius: 4px;
  }
}

.disabled {
  cursor: not-allowed;
  background-color: $gray-light;
  color: white;
}

.between {
  background-color: $red-lighter;
}

.active {
  background-color: $primary-color;
  color: white;
  border-radius: 4px;
}
