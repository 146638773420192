@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";

.main {
  width: 250px;
  position: fixed;
  height: 100%;
  z-index: 999;
  background: $gray-lightest;
  top: 0;
  left: -300px;

  &.hidden {
    display: none;
  }
  &.active {
    left: 0;
  }
}

.inner {
  background: linear-gradient(
    90deg,
    rgba($gray-lightest, 0.4) 0%,
    rgba(250, 250, 250, 1) 90%,
    rgba(245, 245, 245, 1) 100%
  );
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 60px;

  & span {
    color: black;
  }

  @include media-breakpoint-up(md) {
    margin: 24px 0px;
  }
}

.nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ul {
    list-style: none;
    padding-left: 24px;
    margin-bottom: 0;
  }
  ul:first-child .item {
    font-weight: bold;
  }
}

.item {
  font-size: 15px;
  line-height: 20px;
  padding: 6px;
  display: block;
  padding-left: 48px;
  border-radius: 3px;
  text-decoration: none !important;
  color: $black;
  width: calc(100% + 8px);

  &:active,
  &:hover,
  &:visited {
    color: inherit;
  }

  &.active {
    background: $primary-color;
    color: $white;
  }
}

.divider {
  width: 90%;
  background: $gray-lightest;
  height: 1px;
  margin: 12px 0 20px 0;
}

.footer {
  color: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
}

.footer-label {
  font-size: 10px;
  margin-bottom: 6px;
}

.dropdown {
  min-width: 100px !important;
}

.dbutton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: inherit;

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
}

.close-icon {
  margin-left: 6px;
  margin-right: -18px;
  font-weight: bold;
  &:global(.ant-btn) {
    padding: 0;
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }
}
