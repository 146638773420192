@import "../../scss/colors";

.bar {
  width: 100%;
  height: 3px;
}

.meter {
  height: 100%;
  background-color: $primary-color;
}
