@import "redcircle-ui/scss/utility";

.summary-page-section-wrapper {
  padding: 24px 0;
  background: #ffffff;
  border-radius: 12px;
  @include box-shadow(3);

  .summary-page-section-wrapper--header,
  .summary-page-section-wrapper--body {
    padding: 0 24px;
  }

  .summary-page-section-wrapper--body > *:last-child {
    margin-bottom: 0 !important;
  }
}
