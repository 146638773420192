@import "redcircle-ui/scss/colors";
@import "redcircle-ui/scss/media";

//urls
$footer-height: 100px;

.logged-out-frame {
  min-height: 100vh;
  padding-bottom: $footer-height;
  position: relative;

  @include media-breakpoint-down(md) {
    padding-bottom: 170px; // fixes issue with mobile footer blocking button.
  }
}
.logged-out-frame__footer {
  position: absolute;
  bottom: 0px;
  min-height: $footer-height;
  height: auto;
  width: 100%;
  padding: 0 20px;
  background: $white;
}
.logged-out-frame__image {
  height: 15px;
  @include media-breakpoint-up(md) {
    height: 30px;
  }
}

.logged-out-frame__nav {
  line-height: 1.1em;
  font-size: 15px;

  a {
    padding: 0 16px 16px;
    color: $black;
    position: relative;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }

    &.logged-out-frame__nav-link--active {
      color: $primary-color;
      &:after {
        content: "";
        background: $primary-color;
        width: 100%;
        border-radius: 3px;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}

.logged-out-frame__topbar {
  background: $white;
  padding: 32px 28px;

  @include media-breakpoint-down(md) {
    padding: 20px 10px;
  }
}

.logged-out-frame.withShadow {
  .logged-out-frame__topbar {
    background: linear-gradient(180deg, #ffffff 1.27%, #ffffff 100%);
    box-shadow:
      0px 0px 8px rgba(0, 0, 0, 0.04),
      0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 8px 8px;
  }
}

@mixin mobile-text() {
  @include media-breakpoint-down(md) {
    text-align: center;
    display: block;
    width: 100%;
  }
}

.logged-out-frame__footer__copyright {
  float: left;
  height: 43px;
  @include mobile-text;
}
.logged-out-frame__footer__links {
  float: right;
  height: 43px;
  @include media-breakpoint-down(md) {
    width: 100% !important;
    padding-bottom: 20px;
    height: auto;
  }

  a {
    @include mobile-text;
    @include media-breakpoint-up(md) {
      margin: 0 10px;
    }
  }
}
.redcircle-divider.sign-in-page__first-divider {
  margin-bottom: 20px;
}

.logged-out-frame__content.container {
  margin: 30px auto 0px auto;
  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }
}

@include media-breakpoint-down(md) {
  .logged-out-frame__logo {
    position: relative;
    top: -10px;
    margin-left: 0px;
  }
  .logged-out-frame__footer__copyright {
    line-height: 0.8em;
  }
  .logged-out-frame__nav-link--active {
    display: none;
  }
  .logged-out-frame__nav a:last-child {
    margin-right: 5px;
  }
}
