$xxxs: 4px;
$xxs: 8px;
$xs: 16px;
$s: 24px;
$m: 32px;
$l: 40px;
$xl: 64px;
$xxl: $s * 4;
$xxxl: $s * 6;

$a: "";
$h: "h";
$v: "v";
$auto: "a";
$null: "0";
$neg: "-";

$sizes: (
  $auto: auto,
  $null: 0,
  xxxs: $xxxs,
  xxs: $xxs,
  xs: $xs,
  s: $s,
  l: $l,
  m: $m,
  xl: $xl,
  xxl: $xxl,
  xxxl: $xxxl,
);

@each $name, $size in $sizes {
  /* margin-top */
  .m-t#{if($name != "",$name, "")} {
    margin-top: #{$size} !important;
  }
  /* margin-right */
  .m-r#{if($name != "",$name, "")} {
    margin-right: #{$size} !important;
  }
  /* margin-bottom */
  .m-b#{if($name != "",$name, "")} {
    margin-bottom: #{$size} !important;
  }
  /* margin-left */
  .m-l#{if($name != "",$name, "")} {
    margin-left: #{$size} !important;
  }
  /* horizontal margins */
  .m-x#{if($name != "",$name, "")},
  .m-h#{if($name != "",$name, "")} {
    margin-left: #{$size} !important;
    margin-right: #{$size} !important;
  }
  /* vertical margins */
  .m-y#{if($name != "",$name, "")},
  .m-v#{if($name != "",$name, "")} {
    margin-top: #{$size} !important;
    margin-bottom: #{$size} !important;
  }
  /* all margins */
  .m-a#{if($name != "",$name, "")} {
    margin: #{$size} !important;
  }
}

@each $name, $size in $sizes {
  /* padding-top */
  .p-t#{if($name != "",$name, "")} {
    padding-top: #{$size} !important;
  }
  /* padding-right */
  .p-r#{if($name != "",$name, "")} {
    padding-right: #{$size} !important;
  }
  /* padding-bottom */
  .p-b#{if($name != "",$name, "")} {
    padding-bottom: #{$size} !important;
  }
  /* padding-left */
  .p-l#{if($name != "",$name, "")} {
    padding-left: #{$size} !important;
  }
  /* horizontal paddings */
  .p-x#{if($name != "",$name, "")},
  .p-h#{if($name != "",$name, "")} {
    padding-left: #{$size} !important;
    padding-right: #{$size} !important;
  }
  /* vertical paddings */
  .p-y#{if($name != "",$name, "")},
  .p-v#{if($name != "",$name, "")} {
    padding-top: #{$size} !important;
    padding-bottom: #{$size} !important;
  }
  /* all paddings */
  .p-a#{if($name != "",$name, "")} {
    padding: #{$size} !important;
  }
}
